import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { Component } from 'react';
import Translate, { Localization } from '../../../localization/Localization';
import { Currencies } from '../../../shared/models/Currencies';
import { MeasurementSystemType } from '../../../shared/models/MeasurementSystemType';
import { Point } from '../../../shared/models/Point';
import ToastService from '../../../ToastService';
import BusinessMessages from '../../../utils/BusinessMessages';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import { RouteComponentProps, withRouter } from '../../../withRouter';
import { ProjectVersion } from '../../Home/services/dataContracts/queryStack/ProjectVersion';
import { CostRatio } from '../../ProjectSettings/services/dataContracts/queryStack/CostRatio';
import { MergedProjectVersion } from '../../RoadsCondition/models/MergedProjectVersion';
import { RouteLocationStateModel } from '../../RoadsCondition/models/RouteLocationStateModel';
import { RoadsConditionAndScenariosShared } from '../../RoadsCondition/RoadsConditionAndScenariosShared';
import { AddProgrammingRequestArgs } from '../services/dataContracts/controller/AddProgrammingRequestArgs';
import { DeleteProgrammingRequestArgs } from '../services/dataContracts/controller/DeleteProgrammingRequestArgs';
import { DuplicateProgrammingRequestArgs } from '../services/dataContracts/controller/DuplicateProgrammingRequestArgs';
import { UpdateLabelAndYearProgrammingRequestArgs } from '../services/dataContracts/controller/UpdateLabelAndYearProgrammingRequestArgs';
import { Programming } from '../services/dataContracts/queryStack/Programming';
import { ProgrammingsApiClient } from '../services/ProgrammingsApiClient';
import { AddOrUpdateProgrammingComponent } from './components/AddOrUpdateProgrammingComponent';
import { ProgrammingsListComponent } from './components/ProgrammingsListComponent';
import { ProgrammingsMapComponent } from './components/ProgrammingsMapComponent';
import { ProgrammingsModuleDescriptionComponent } from './components/ProgrammingsModuleDescriptionComponent';
import './ProgrammingsManagementStyles.scss';

interface ProgrammingsManagementState {
    loading: boolean,
    programmings: Programming[],
    isCreateEditProgrammingContentVisible: boolean,
    currencySymbole: string,
    isProgrammingInEdit: boolean,
    programmingInEdit: Programming,
    mergedProjects: Map<number, MergedProjectVersion>,
    measurementSystemType: MeasurementSystemType,
    isImpossibleProgrammingMigrationDialogOpened: boolean
}

const initialState: ProgrammingsManagementState = {
    loading: false,
    programmings: null,
    isCreateEditProgrammingContentVisible: false,
    currencySymbole: null,
    isProgrammingInEdit: false,
    programmingInEdit: null,
    mergedProjects: null,
    measurementSystemType: null,
    isImpossibleProgrammingMigrationDialogOpened: false
}

export class ProgrammingsManagementView extends Component<RouteComponentProps, ProgrammingsManagementState> {
    _isMounted: boolean;
    projectId: string;
    projectVersionId: number;
    locationGeometry: Point;
    projectVersionsCache: Map<number, ProjectVersion>;
    mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>;
    costRatiosCache: Map<number, CostRatio[]>;

    constructor(props) {
        super(props);

        initialState.measurementSystemType = MeasurementSystem.getCurrentType();

        this.costRatiosCache = new Map<number, CostRatio[]>();

        this.state = initialState;
    }

    async componentDidMount() {
        this._isMounted = true;

        this.projectVersionsCache = new Map<number, ProjectVersion>();
        this.mergedProjectAuscultationsCache = new Map<number, MergedProjectVersion>();

        let locationState = this.props.location.state as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        this.projectId = locationState.projectId;
        this.projectVersionId = locationState.projectVersionId;
        this.locationGeometry = locationState.locationGeometry;

        const query = new URLSearchParams(this.props.location.search);
        const programmingId = Number(query.get('programmingId'));

        this.setState({
            loading: true
        });

        await Promise.all([
            ProgrammingsApiClient.GetProjectCurrency(this.projectId),
            ProgrammingsApiClient.GetProgrammings(this.projectId)
        ])
            .then(async (results) => {
                let currencySymbole = Currencies[results[0].data];
                let programmings = results[1].data;

                let yearApiCalls: Promise<AxiosResponse<CostRatio[]>>[] = [];
                let years = programmings.map(x => x.year);
                let yearsSet = new Set(years);
                yearsSet.forEach((year) => {
                    yearApiCalls.push(ProgrammingsApiClient.GetCostRatios(this.projectId, year));
                });

                let costRatiosData = await Promise.all(yearApiCalls);
                costRatiosData.forEach((res) => {
                    let data = res.data;
                    if (data.length > 0) {
                        this.costRatiosCache.set(data[0].year, data);
                    }
                });

                let apiCalls: Promise<MergedProjectVersion>[] = [];
                let projectVersionIds = programmings.map(x => x.projectVersionId);
                let ids = new Set(projectVersionIds);
                if (!ids.has(this.projectVersionId)) {
                    ids.add(this.projectVersionId);
                }
                ids.forEach((projectVersionId) => {
                    apiCalls.push(RoadsConditionAndScenariosShared.getMergedProject(projectVersionId, this.mergedProjectAuscultationsCache, this.projectVersionsCache))
                });

                await Promise.all(apiCalls).then(() => {
                    let state: ProgrammingsManagementState = { ...this.state };

                    if (programmingId) {
                        let programming = programmings.find(x => x.programmingId === programmingId);
                        state.isCreateEditProgrammingContentVisible = true;
                        state.isProgrammingInEdit = true;
                        state.programmingInEdit = programming;
                    }

                    state.currencySymbole = currencySymbole;
                    state.programmings = programmings;
                    state.mergedProjects = this.mergedProjectAuscultationsCache;
                    state.loading = false;

                    this.setState(state);
                })
            });
    }

    handleCreateProgrammingClicked = (): void => {
        this.setState({
            isCreateEditProgrammingContentVisible: true,
            isProgrammingInEdit: false,
            programmingInEdit: null
        });
    }

    handleCancelAddOrUpdateProgrammingClicked = (): void => {
        const query = new URLSearchParams(this.props.location.search);
        if (query.get('programmingId')) {
            this.props.navigate(-1);
            return;
        }

        this.setState({
            loading: true
        });

        ProgrammingsApiClient.GetProgrammings(this.projectId)
            .then((res) => {
                this.setState({
                    programmings: res.data,
                    isCreateEditProgrammingContentVisible: false,
                    isProgrammingInEdit: false,
                    programmingInEdit: null,
                    loading: false
                });
            });
    }

    handleAddProgrammingClicked = (label: string, year: number): void => {
        this.setState({
            loading: true
        });

        let args: AddProgrammingRequestArgs = {
            projectId: this.projectId,
            projectVersionId: this.projectVersionId,
            label: label,
            year: year,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        ProgrammingsApiClient.AddProgramming(args)
            .then(res => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }

                const programmingId: number = data.customData;
                this.navigateToProgrammingAreasManagement(programmingId);

                this.setState({
                    loading: false
                });
            });
    }

    handleUpdateProgrammingClicked = (label: string, year: number, state: ProgrammingsManagementState): void => {
        this.setState({
            loading: true
        });

        let args: UpdateLabelAndYearProgrammingRequestArgs = {
            programmingId: state.programmingInEdit.programmingId,
            label: label,
            year: year,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        ProgrammingsApiClient.UpdateProgrammingLabelAndYear(args)
            .then((res) => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }

                this.handleCancelAddOrUpdateProgrammingClicked();
            });
    }

    navigateToProgrammingAreasManagement = (programmingId: number): void => {
        let urlRedirect = `/ProgrammingAreasManagement?programmingId=${programmingId}`;
        let locationState = this.props.location.state as RouteLocationStateModel;
        this.props.navigate(urlRedirect, { state: locationState });
    }

    handleOpenProgramming = (programmingId: number): void => {
        this.navigateToProgrammingAreasManagement(programmingId);
    }

    handleEditProgramming = (programmingId: number, state: ProgrammingsManagementState): void => {
        let programming = state.programmings.find(x => x.programmingId === programmingId);

        this.setState({
            isCreateEditProgrammingContentVisible: true,
            isProgrammingInEdit: true,
            programmingInEdit: programming
        });
    }

    handleDeleteProgramming = (programmingId: number): void => {
        let args: DeleteProgrammingRequestArgs = {
            programmingId: programmingId,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        ProgrammingsApiClient.DeleteProgramming(args)
            .then(res => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }

                ProgrammingsApiClient.GetProgrammings(this.projectId)
                    .then((res) => {
                        let programmings = res.data;

                        this.setState({
                            loading: false,
                            programmings: programmings
                        });
                    });
            });
    }

    handleDuplicateProgramming = (projectId: string, projectVersionId: number, projectVersionNumber: number, programmingId: number): void => {
        this.setState({
            loading: true
        });

        let args: DuplicateProgrammingRequestArgs = {
            projectId: projectId,
            projectVersionId: projectVersionId,
            projectVersionNumber: projectVersionNumber,
            programmingId: programmingId,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        ProgrammingsApiClient.DuplicateProgramming(args)
            .then(res => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }

                if (data as any === false) {
                    this.setState({
                        isImpossibleProgrammingMigrationDialogOpened: true,
                        loading: false
                    });
                    return;
                }

                ToastService.showSuccessToast(Translate.Resources.UI_ProgrammingsManagementView_ProgramWasSuccessfullyMigrated);

                ProgrammingsApiClient.GetProgrammings(this.projectId)
                    .then((res) => {
                        let programmings = res.data;

                        this.setState({
                            loading: false,
                            programmings: programmings
                        });
                    });
            });
    }

    handleMeasurementSystemTypeChanged = (measurementSystemType: MeasurementSystemType): void => {
        this.setState({
            measurementSystemType
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const state = this.state;

        return (
            <Box className="programmings">
                <Box className="programmings-content">
                    {state.isCreateEditProgrammingContentVisible ?
                        (<AddOrUpdateProgrammingComponent projectId={this.projectId}
                            projectVersionId={this.projectVersionId}
                            inEdit={state.isProgrammingInEdit}
                            programmingInEdit={state.programmingInEdit}
                            handleAddProgrammingClicked={this.handleAddProgrammingClicked}
                            handleUpdateProgrammingClicked={(label: string, year: number) => this.handleUpdateProgrammingClicked(label, year, state)}
                            handleCancel={() => this.handleCancelAddOrUpdateProgrammingClicked()} />) :
                        (state.programmings ?
                            (state.programmings.length > 0 ?
                                (<ProgrammingsListComponent programmingsList={state.programmings}
                                    currency={state.currencySymbole}
                                    mergedProjects={state.mergedProjects}
                                    projectId={this.projectId}
                                    costRatiosCache={this.costRatiosCache}
                                    currentProjectVersionId={this.projectVersionId}
                                    handleCreateProgrammingClicked={this.handleCreateProgrammingClicked}
                                    handleOpenProgramming={this.handleOpenProgramming}
                                    handleEditProgramming={(programmingId: number) => this.handleEditProgramming(programmingId, state)}
                                    handleDeleteProgramming={this.handleDeleteProgramming}
                                    handleDuplicateProgramming={this.handleDuplicateProgramming}
                                />) :
                                (<ProgrammingsModuleDescriptionComponent handleCreateProgrammingClicked={this.handleCreateProgrammingClicked} />)
                            ) : '')
                    }
                </Box>
                {this.locationGeometry &&
                    <ProgrammingsMapComponent locationGeometry={this.locationGeometry} projectVersionId={this.projectVersionId} mergedProjects={state.mergedProjects} programmingInEdit={state.programmingInEdit} loading={state.loading} />
                }
                {state.isImpossibleProgrammingMigrationDialogOpened &&
                    <Dialog id="impossible-Programming-migration-dialog" open={state.isImpossibleProgrammingMigrationDialogOpened}>
                        <DialogTitle className="title-icon">
                            <IconButton onClick={() => {
                                this.setState({
                                    isImpossibleProgrammingMigrationDialogOpened: false
                                });
                            }}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Box display="flex" flexDirection="row" className="title" alignItems="center" justifyContent="center">
                                <ReplayIcon className="migrate-icon" />
                                <div>{Translate.Resources.UI_Progrmmings_ProgrmmingCard_Menu_MigrateProgrmmingDialog_StatementMigration}</div>
                            </Box>
                            <Box>
                                <Box className="text">
                                    {Translate.Resources.UI_Progrmmings_AutomaticMigrationImpossible}
                                </Box>
                                <Box className="text">
                                    {Translate.Resources.UI_Progrmmings_NecessaryToCreateANewProgram}
                                </Box>
                            </Box>
                        </DialogContent>
                    </Dialog>
                }
            </Box>
        );
    }
}

export default React.forwardRef(withRouter(ProgrammingsManagementView));