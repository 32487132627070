import { data, Map as AzureMap, source } from 'azure-maps-control';
import React, { useEffect, useRef, useState } from "react";
import { barAction, MapActionBar } from "../../../shared/components/MapActionBar/MapActionBar";
import { attributeEmpty, attributeEmptyColor, attributesLayers, bikeLaseColors, borderColors, busColors, ditchColors, environmentColors, getColors, hierarchyColors, managerColors, sideColors, trafficColors } from "../../../shared/Map/AttributesColors";
import { createMap, createShape, getLayerSectionShapeId, getMapChoiceValue, getSectionShapeId, getSelectedSectionShapeId, sectionWidth, selectedSectionWidth, setMapCameraFromPositions, setMapCursor, setMapZoom, transparentColor } from "../../../shared/Map/MapUtils";
import { MapCursorMode } from "../../../shared/models/MapCursorMode";
import { MeasurementSystemType } from "../../../shared/models/MeasurementSystemType";
import { Point } from "../../../shared/models/Point";
import { ShapeEntityType } from "../../../shared/models/ShapeEntityType";
import { RoadSectionViewData } from "../../../views/RoadsCondition/models/RoadSectionViewData";
import styles from "../../../_variables2.scss";
import { MergedProjectVersion } from "../../RoadsCondition/models/MergedProjectVersion";
import { RoadsConditionAndScenariosShared } from "../../RoadsCondition/RoadsConditionAndScenariosShared";
import { DisplayMode } from "../HighwaysView";
import './HighwaysMaps.scss';

interface HighwaysMapViewProps {
    locationGeometry: Point
    mergedProjectVersion: MergedProjectVersion,
    displayMode: DisplayMode,
    selectedSectionsId: number[],
    filteredSectionsId: number[],
    filteredSectionsIdFromSearchText: number[],
    isGridHasActiveFilters: boolean,
    isLayerMenuDisplayed: boolean,
    layersFilter: { [id: string]: string[] },
    layersOrder: string[],
    onSelectedSectionChange: (sectionsId: number[]) => void,
    isMapInit: (isMapInit: boolean) => void,
    currentMeasurementSystemType: MeasurementSystemType,
    searchText: string
}

const mainLayer = "main";

export const HighwaysMap = (props: HighwaysMapViewProps) => {

    const azureMap = useRef<AzureMap>();
    const [isLayerMenuDisplayed, setIsLayerMenuDisplayed] = useState<boolean>(props.isLayerMenuDisplayed);
    const [filteredSections, setFilteredSections] = useState<Map<number, RoadSectionViewData>>(new Map());
    const [filteredSectionsFromSearchText, setFilteredSectionsFromSearchText] = useState<Map<number, RoadSectionViewData>>(new Map());

    useEffect(() => {
        if (!azureMap.current) {
            let mapChoice = getMapChoiceValue();
            azureMap.current = createMap('AzureMap', 4, props.locationGeometry, mapChoice);
        }

        if (props.mergedProjectVersion === null)
            return;

        if (!props.filteredSectionsId.length && !Object.keys(props.layersFilter)?.length) {
            setFilteredSections(props.mergedProjectVersion?.roadsSections);
            initMap(azureMap.current, () => {
                createDatasource(mainLayer);

                createMapSectionsShapes(props.mergedProjectVersion);
                Object.keys(attributesLayers).forEach(e => {
                    createDatasource(attributesLayers[e]);
                    createAttributesSectionsShapes(props.mergedProjectVersion.roadsSections, attributesLayers[e], props.layersFilter)
                });

                setOrderLayers(props.layersOrder);
                setMapZoom(azureMap.current, props.mergedProjectVersion, 20);
                props.isMapInit(true);
            });
        }
    }, [props.mergedProjectVersion]);

    useEffect(() => {
        setIsLayerMenuDisplayed(props.isLayerMenuDisplayed);
        azureMap.current.resize();
    }, [props.isLayerMenuDisplayed, props.displayMode]);

    useEffect(() => {
        if (!filteredSections)
            return;

        setLayerFilters(props.layersFilter, filteredSections, filteredSectionsFromSearchText, props.selectedSectionsId);
        setOrderLayers(props.layersOrder);

    }, [JSON.stringify(props.layersFilter)]);

    useEffect(() => {
        if (props.mergedProjectVersion === null)
            return;

        setOrderLayers(props.layersOrder);
    }, [JSON.stringify(props.layersOrder)]);


    useEffect(() => {
        if (!props.mergedProjectVersion?.roadsSections)
            return;

        createMapSectionsShapes(props.mergedProjectVersion, props.selectedSectionsId);
        Object.values(attributesLayers).forEach(layer => {
            let sections = props.mergedProjectVersion.roadsSections;
            if (props.isGridHasActiveFilters) {
                sections = setGridFilters(props.mergedProjectVersion, props.filteredSectionsId);
                setFilteredSections(sections);
            }

            let intersectionSections = new Map<number, RoadSectionViewData>();
            if (props.searchText?.length > 2) {
                for (const [key, value] of sections) {
                    if (filteredSectionsFromSearchText.has(key)) {
                        intersectionSections.set(key, value);
                    }
                }
            }
            else {
                intersectionSections = sections;
            }

            createAttributesSectionsShapes(intersectionSections, layer, props.layersFilter, props.selectedSectionsId);
        });
    }, [JSON.stringify(props.selectedSectionsId)]);

    useEffect(() => {
        if (!azureMap.current?.sources?.getSources()?.length) {
            return;
        }

        let sections = setGridFilters(props.mergedProjectVersion, props.filteredSectionsId);
        setFilteredSections(sections);

        setLayerFilters(props.layersFilter, sections, filteredSectionsFromSearchText, props.selectedSectionsId);
    }, [JSON.stringify(props.filteredSectionsId), props.isGridHasActiveFilters]);

    useEffect(() => {
        if (!azureMap.current?.sources?.getSources()?.length) {
            return;
        }

        let sections = setGridFiltersFromSearchText(props.mergedProjectVersion, props.filteredSectionsIdFromSearchText);
        setFilteredSectionsFromSearchText(sections);

        setLayerFilters(props.layersFilter, filteredSections, sections, props.selectedSectionsId);
    }, [props.filteredSectionsIdFromSearchText]);

    const setLayerFilters = (layerFilters: { [id: string]: string[]; }, filteredSections: Map<number, RoadSectionViewData>, filteredSectionsFromSearchText: Map<number, RoadSectionViewData>, selectedSectionsId: number[]) => {
        let layers = Object.keys(layerFilters);

        createMapSectionsShapes(props.mergedProjectVersion, props.selectedSectionsId);

        let intersectionSections = new Map<number, RoadSectionViewData>();
        if (props.searchText?.length > 2) {
            for (const [key, value] of filteredSections) {
                if (filteredSectionsFromSearchText.has(key)) {
                    intersectionSections.set(key, value);
                }
            }
        }
        else {
            intersectionSections = filteredSections;
        }

        if (intersectionSections.size > 0) {
            SetDynamicZoom(azureMap.current, intersectionSections);
        }
        else {
            if (props.mergedProjectVersion) {
                setMapZoom(azureMap.current, props.mergedProjectVersion, 20);
            }
        }

        layers.forEach(layer => {
            createAttributesSectionsShapes(intersectionSections, layer, layerFilters, selectedSectionsId);
        });
    }

    const setGridFilters = (mergedProjectVersion: MergedProjectVersion, filteredSectionsId: number[]): Map<number, RoadSectionViewData> => {
        let updatedFilteredSections: Map<number, RoadSectionViewData> = new Map();

        if (props.isGridHasActiveFilters) {
            filteredSectionsId.forEach(s => {
                updatedFilteredSections.set(s, mergedProjectVersion.roadsSections.get(s));
            });
        }
        else {
            updatedFilteredSections = mergedProjectVersion.roadsSections;
        }

        return updatedFilteredSections;
    }

    const setGridFiltersFromSearchText = (mergedProjectVersion: MergedProjectVersion, filteredSectionsIdFromSearchText: number[]): Map<number, RoadSectionViewData> => {
        let updatedFilteredSections: Map<number, RoadSectionViewData> = new Map();

        if (props.searchText?.length > 2) {
            filteredSectionsIdFromSearchText.forEach(s => {
                updatedFilteredSections.set(s, mergedProjectVersion.roadsSections.get(s));
            });
        }
        else {
            updatedFilteredSections = mergedProjectVersion.roadsSections;
        }

        return updatedFilteredSections;
    }

    const SetDynamicZoom = (azureMap: AzureMap, roadsSections: Map<number, RoadSectionViewData>) => {
        let sectionsPositions: data.Position[] = [];
        roadsSections.forEach(s => {
            s.pathGeometry.coordinates.forEach(p => sectionsPositions.push(p));
        });
        setMapCameraFromPositions(azureMap, sectionsPositions);
    }

    const getDatasource = (layer: string): source.DataSource =>
        azureMap.current.sources.getById(`${layer}-datasource`) as source.DataSource;

    const createDatasource = (layer: string): source.DataSource => {
        let existingSource = getDatasource(layer);
        if (existingSource)
            return existingSource;

        let datasource = new source.DataSource(`${layer}-datasource`);
        azureMap.current.sources.add(datasource);
        let lineLayer = RoadsConditionAndScenariosShared.createLineLayer(datasource, layer);
        azureMap.current.layers.add([lineLayer]);

        return datasource;
    }

    const setOrderLayers = (layersOrder: string[]) => {
        let n = 0
        while (n < layersOrder.length - 1) {
            let topLayer = azureMap.current.layers.getLayerById(layersOrder[n]);
            let currentLayer = azureMap.current.layers.getLayerById(layersOrder[n + 1]);
            azureMap.current.layers.move(currentLayer, topLayer);
            n++;
        }
    }

    const createMapSectionsShapes = (mergedProject: MergedProjectVersion, selectionSectionIds?: number[]): void => {
        let dataSource = getDatasource(mainLayer);
        if (!dataSource)
            return;

        dataSource.clear();
        mergedProject.roadsSections.forEach((section: RoadSectionViewData) => {
            let coordinates = section.pathGeometry.coordinates;
            let roadSectionId = section.roadSectionId;

            if (selectionSectionIds && selectionSectionIds.includes(roadSectionId)) {
                //Selected Layer
                let selectedSectionShapeId = getSelectedSectionShapeId(roadSectionId);
                let selectedSectionShape = createShape(coordinates, selectedSectionShapeId, styles.selectedSectionColor, selectedSectionWidth, ShapeEntityType.sectionSelected, roadSectionId);
                dataSource.add(selectedSectionShape);
            }

            //Not Selected Layer
            let unselectedSectionShapeId = getSectionShapeId(roadSectionId);
            let unselectedSectionShape = createShape(coordinates, unselectedSectionShapeId, styles.emptyAttributeSectionColor, sectionWidth, ShapeEntityType.section, roadSectionId, null, section.score);
            dataSource.add(unselectedSectionShape);
        });
    }

    const createAttributesSectionsShapes = (roadsSections: Map<number, RoadSectionViewData>, layer: string, filter: { [id: string]: string[] }, selectionSectionIds?: number[]): void => {
        let dataSource = getDatasource(layer);
        dataSource.clear();
        roadsSections.forEach((section: RoadSectionViewData) => {
            if (!hasFilters(section, layer, filter))
                return;

            let coordinates = section.pathGeometry.coordinates;
            let roadSectionId = section.roadSectionId;

            if (selectionSectionIds && selectionSectionIds.includes(roadSectionId)) {
                //Selected Layer
                let selectedSectionShapeId = getSelectedSectionShapeId(roadSectionId);
                let selectedSectionShape = createShape(coordinates, selectedSectionShapeId, styles.selectedSectionColor, selectedSectionWidth, ShapeEntityType.sectionSelected, roadSectionId);
                dataSource.add(selectedSectionShape);
            }

            //Not Selected Layer
            let attributeColor = getAttributesSectionColor(layer, section);
            let layerSectionId = getLayerSectionShapeId(roadSectionId, layer);
            let attributeSectionShape = createShape(coordinates, layerSectionId, attributeColor, sectionWidth, ShapeEntityType.section, roadSectionId, null, section.score);
            dataSource.add(attributeSectionShape);
        });
    }

    //Todo @NFR, refacto pour le rendre générique
    const hasFilters = (section: RoadSectionViewData, layer: string, filter: { [id: string]: string[] }): boolean => {
        let hasFilter = false;
        switch (layer) {
            case attributesLayers["hierarchy"]: {
                hasFilter = filter[attributesLayers["hierarchy"]]?.includes(section.hierarchy)
                    || (filter[attributesLayers["hierarchy"]]?.includes(attributeEmpty) && section.hierarchy === null) ? true : false;
                break;
            }
            case attributesLayers["environment"]: {
                hasFilter = filter[attributesLayers["environment"]]?.includes(section.environment)
                    || (filter[attributesLayers["environment"]]?.includes(attributeEmpty) && section.environment === null) ? true : false;
                break;
            }
            case attributesLayers["traffic"]: {
                hasFilter = filter[attributesLayers["traffic"]]?.includes(section.traffic)
                    || (filter[attributesLayers["traffic"]]?.includes(attributeEmpty) && section.traffic === null) ? true : false;
                break;
            }
            case attributesLayers["manager"]: {
                hasFilter = filter[attributesLayers["manager"]]?.includes(section.manager)
                    || (filter[attributesLayers["manager"]]?.includes(attributeEmpty) && section.manager === null) ? true : false;
                break;
            }
            case attributesLayers["bus"]: {
                hasFilter = filter[attributesLayers["bus"]]?.includes(section.bus?.toString())
                    || (filter[attributesLayers["bus"]]?.includes(attributeEmpty) && section.bus === null) ? true : false;
                break;
            }
            case attributesLayers["bikeLase"]: {
                hasFilter = filter[attributesLayers["bikeLase"]]?.includes(section.bikeLase?.toString())
                    || (filter[attributesLayers["bikeLase"]]?.includes(attributeEmpty) && section.bikeLase === null) ? true : false;
                break;
            }
            case attributesLayers["border"]: {
                hasFilter = filter[attributesLayers["border"]]?.includes(section.border?.toString())
                    || (filter[attributesLayers["border"]]?.includes(attributeEmpty) && section.border === null) ? true : false;
                break;
            }
            case attributesLayers["ditch"]: {
                hasFilter = filter[attributesLayers["ditch"]]?.includes(section.ditch?.toString())
                    || (filter[attributesLayers["ditch"]]?.includes(attributeEmpty) && section.ditch === null) ? true : false;
                break;
            }
            case attributesLayers["side"]: {
                hasFilter = filter[attributesLayers["side"]]?.includes(section.side?.toString())
                    || (filter[attributesLayers["side"]]?.includes(attributeEmpty) && section.side === null) ? true : false;
                break;
            }
            case attributesLayers["municipality"]: {
                hasFilter = filter[attributesLayers["municipality"]]?.includes(section.municipality)
                    || (filter[attributesLayers["municipality"]]?.includes(attributeEmpty) && section.municipality === null) ? true : false;
                break;
            }
            case attributesLayers["district"]: {
                hasFilter = filter[attributesLayers["district"]]?.includes(section.district)
                    || (filter[attributesLayers["district"]]?.includes(attributeEmpty) && section.district === null) ? true : false;
                break;
            }
            case attributesLayers["collaborativeDevelopmentZone"]: {
                hasFilter = filter[attributesLayers["collaborativeDevelopmentZone"]]?.includes(section.collaborativeDevelopmentZone)
                    || (filter[attributesLayers["collaborativeDevelopmentZone"]]?.includes(attributeEmpty) && section.collaborativeDevelopmentZone === null) ? true : false;
                break;
            }
            default: {
                hasFilter = false;
                break;
            }
        }
        return hasFilter;
    }

    const initMap = (map: AzureMap, callback: () => void): void => {
        setMapCursor(map, MapCursorMode.Auto);

        map.events.add('load', () => {
            if (callback) {
                callback();
            }
        });
    }

    const getAttributesSectionColor = (attributeLayer: string, section: RoadSectionViewData) => {
        let color: string;
        switch (attributeLayer) {
            case attributesLayers["hierarchy"]: {
                color = section.hierarchy === null ? attributeEmptyColor : hierarchyColors[section.hierarchy];
                break;
            }
            case attributesLayers["environment"]: {
                color = section.environment === null ? attributeEmptyColor : environmentColors[section.environment];
                break;
            }
            case attributesLayers["traffic"]: {
                color = section.traffic === null ? attributeEmptyColor : trafficColors[section.traffic];
                break;
            }
            case attributesLayers["manager"]: {
                color = section.manager === null ? attributeEmptyColor : managerColors[section.manager];
                break;
            }
            case attributesLayers["bus"]: {
                color = section.bus === null ? attributeEmptyColor : busColors[section.bus.toString()];
                break;
            }
            case attributesLayers["bikeLase"]: {
                color = section.bikeLase === null ? attributeEmptyColor : bikeLaseColors[section.bikeLase.toString()];
                break;
            }
            case attributesLayers["border"]: {
                color = section.border === null ? attributeEmptyColor : borderColors[section.border.toString()];
                break;
            }
            case attributesLayers["ditch"]: {
                color = section.ditch === null ? attributeEmptyColor : ditchColors[section.ditch.toString()];
                break;
            }
            case attributesLayers["side"]: {
                color = section.side === null ? attributeEmptyColor : sideColors[section.side.toString()];
                break;
            }
            case attributesLayers["municipality"]: {
                color = section.municipality === null ? attributeEmptyColor : getColors(attributeLayer, Array.from(props.mergedProjectVersion.municipalities))[section.municipality.toString()];
                break;
            }
            case attributesLayers["district"]: {
                color = section.district === null ? attributeEmptyColor : getColors(attributeLayer, Array.from(props.mergedProjectVersion.districts))[section.district.toString()];
                break;
            }
            case attributesLayers["collaborativeDevelopmentZone"]: {
                color = section.collaborativeDevelopmentZone === null ? attributeEmptyColor : getColors(attributeLayer, Array.from(props.mergedProjectVersion.collaborativeDevelopmentZones))[section.collaborativeDevelopmentZone.toString()];
                break;
            }
            default: {
                color = transparentColor;
                break;
            }
        }

        return color;
    }

    const getMapActionBarCss = (): string => {
        let css = "map-actions ";

        if (props.displayMode === DisplayMode.Map) {
            css += "display-mode-grid ";
        }
        else if (props.displayMode === DisplayMode.MapAndGrid) {
            css += "display-mode-map-grid ";
        }

        if (isLayerMenuDisplayed) {
            css += "map-actions-with-layer-menu ";
        }
        else {
            css += "map-actions-without-layer-menu ";
        }

        return css;
    }

    return (
        <div className="highwaysMap">
            <div id="measurementInfo" className="measure"></div>
            {azureMap.current && props.displayMode !== DisplayMode.Grid &&
                <div className={getMapActionBarCss()}>
                    {props.mergedProjectVersion &&
                        <MapActionBar
                            azureMap={azureMap.current}
                            actions={[barAction.MonoSelect, barAction.ZoneSelect, barAction.ClearZone, barAction.Measure]}
                            onSelectedSectionChange={props.onSelectedSectionChange}
                            selectedSectionsId={props.selectedSectionsId}
                            sections={props.mergedProjectVersion.roadsSections}
                            mainLayer={mainLayer}
                            currentMeasurementSystemType={props.currentMeasurementSystemType}
                        />
                    }
                </div>
            }
            <div id="AzureMap" className="azuremap"> </div>
        </div>
    );
}