import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import CompareIcon from '@mui/icons-material/Compare';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Box, Button, ButtonGroup } from '@mui/material';
import React, { useState } from 'react';
import iconIncognito from 'src/assets/icons/icon-incognito.svg';
import iconInfracareCarSquare from 'src/assets/icons/icon-infracare-car-square.svg';
import buildinfo from '../../../config/buildinfo.json';
import Translate from '../../../localization/Localization';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import { ImageExtended } from '../../../views/RoadsCondition/models/ImageExtended';
import { RoadSectionViewData } from '../../../views/RoadsCondition/models/RoadSectionViewData';
import { DetectionLabels } from '../../models/DetectionLabels';
import { ScoreTypes } from '../../models/ScoreTypes';
import './RoadSectionDetailsStyles.scss';

const versionMajorMinor: string = buildinfo.versionMajorMinor;
const buildNumber: string = buildinfo.build.buildNumber;

interface InformationItemProps {
    title: string,
    value: string | number
}

const InformationItem = (props: InformationItemProps): JSX.Element => {
    return (
        <Box display="flex" flexDirection="row" mb="5px" alignItems="center">
            <Box className="title" width="60%">{props.title}</Box>
            <Box className="value" width="40%">{props.value}</Box>
        </Box>
    );
}

interface ImageContentProps {
    url: string,
    videoDateTime: string,
    handleOpenImageInNewTab: (url: string) => void
}

const ImageContent = (props: ImageContentProps): JSX.Element => {
    return (
        <figure className="image-figure">
            <img src={props.url} className="img" alt="labeled content"
                onClick={() => props.handleOpenImageInNewTab(props.url)} />
            <figcaption className="caption">
                <Box className="image-date">{props.videoDateTime}</Box>
            </figcaption>
        </figure>
    );
}

interface SectionScoreComponentProps {
    selectedRoadSection: RoadSectionViewData
}

const SectionScoreComponent = (props: SectionScoreComponentProps): JSX.Element => {
    const { selectedRoadSection } = props;
    return (
        <Box className="section-score-component">
            <div className={`score ${selectedRoadSection.scoreType === ScoreTypes.monitoring ? 'monitoring' :
                (selectedRoadSection.scoreType === ScoreTypes.localizedRepair ? 'localized-repair' :
                    (selectedRoadSection.scoreType === ScoreTypes.generalMaintenance ? 'general-maintenance' :
                        (selectedRoadSection.scoreType === ScoreTypes.reinforcement ? 'reinforcement' :
                            (selectedRoadSection.scoreType === ScoreTypes.rehabilitation ? 'rehabilitation' : ''))))}`} >
                {selectedRoadSection.score}
            </div>
            <div className="quality">{selectedRoadSection.scoreType === ScoreTypes.monitoring ? Translate.Resources.UI_ActionsMenu_Quality_Monitoring :
                (selectedRoadSection.scoreType === ScoreTypes.localizedRepair ? Translate.Resources.UI_ActionsMenu_Quality_LocalizedRepair :
                    (selectedRoadSection.scoreType === ScoreTypes.generalMaintenance ? Translate.Resources.UI_ActionsMenu_Quality_GeneralMaintenance :
                        (selectedRoadSection.scoreType === ScoreTypes.reinforcement ? Translate.Resources.UI_ActionsMenu_Quality_Reinforcement :
                            (selectedRoadSection.scoreType === ScoreTypes.rehabilitation ? Translate.Resources.UI_ActionsMenu_Quality_Rehabilitation : ''))))}
            </div>
        </Box>
    )
}

enum ImageDisplayedMode {
    image = "image",
    labeled = "labeled",
    combined = "combined"
}

interface RoadSectionDetailsComponentProps {
    selectedRoadSection: RoadSectionViewData,
    selectedImage: ImageExtended,
    hasScoreAnalysisAccess: boolean,
    role: string,
    handleStepChanged: (selectedImage: ImageExtended, searchByNext: boolean) => void,
    handleImageChanged: (imageId: number) => void,
    onClose: () => void
}

export const RoadSectionDetailsComponent = (props: RoadSectionDetailsComponentProps): JSX.Element => {
    const { selectedRoadSection, selectedImage, hasScoreAnalysisAccess, role } = props;

    const [imageDisplayedMode, setImageDisplayedMode] = useState<ImageDisplayedMode>(hasScoreAnalysisAccess && role !== "CLIPLUS" && role !== "CLI" && role !== "VISU" ? ImageDisplayedMode.combined : ImageDisplayedMode.image);
    const [isDisplayedDetailsOpened, setIsDisplayedDetailsOpened] = useState<boolean>(true);

    const handleOpenImageInNewTab = (url: string): void => {
        let newWindow = window.open(url, '_blank');
        newWindow.addEventListener('load', function () {
            newWindow.document.title = "infraCare v" + versionMajorMinor + "." + buildNumber;
        }, false);
    }

    let displayedImages = selectedRoadSection.images.filter(x => x.roadStepId === selectedImage.roadStepId);
    let videoDateTime = new Date(selectedImage.auscultation.videoDateTime).toLocaleDateString();

    return (
        <Box className="road-section-details">
            <Box display="flex" flexDirection="row" justifyContent="space-between" className="road-section">
                <Box display="flex" flexDirection="row">
                    <Box mr="5px" className="title-heading">{selectedRoadSection.roadLabel ?? ''}</Box>
                    <Box className="paragraphe-large">{`Section ID ${selectedRoadSection.roadSectionId}`}</Box>
                </Box>
                <Box display="flex" flexDirection="row">
                    <Button className={`btn-display-details ${isDisplayedDetailsOpened ? 'details-opened' : ''}`} onClick={() => setIsDisplayedDetailsOpened(!isDisplayedDetailsOpened)}>
                        {isDisplayedDetailsOpened && <FontAwesomeIcon icon={faEye} />}
                        {!isDisplayedDetailsOpened && <FontAwesomeIcon icon={faEyeSlash} />}
                        <span className="label">{Translate.Resources.UI_RoadSectionDetails_SectionInfo}</span>
                    </Button>
                    <CloseIcon fontSize="small" onClick={() => props.onClose()} className="poper-close-icon" />
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" className="details">
                {isDisplayedDetailsOpened &&
                    <Box display="flex" flexDirection="column" className="section-details">
                        <SectionScoreComponent selectedRoadSection={selectedRoadSection} />
                        <Box mb="5px" display="flex" flexDirection="column" className="detections">
                            {selectedRoadSection && selectedRoadSection.anomaliesCounters && Array.from(selectedRoadSection.anomaliesCounters).map((anomaly, index) => {
                                let count = `${Math.round((anomaly[1].percent * 100))} %`;
                                if (anomaly[0] === DetectionLabels.nid_de_poule ||
                                    anomaly[0] === DetectionLabels.nid_de_poule_repare ||
                                    anomaly[0] === DetectionLabels.fissure_transversale ||
                                    anomaly[0] === DetectionLabels.fiss_trans_ponte ||
                                    anomaly[0] === DetectionLabels.emergence_sur_chaussee ||
                                    anomaly[0] === DetectionLabels.tranchee) {
                                    count = `${anomaly[1].counter}`;
                                }

                                return (
                                    <Box key={`detection-${index}`} mb="5px" display="flex" flexDirection="row" alignItems="center" className="item">
                                        <Box display="flex" alignItems="center" className="percent-count">{count} </Box>
                                        <Box className="label" >{Translate.GetAnomalyTypeLabel(anomaly[0])}</Box>
                                    </Box>
                                )
                            })}
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <InformationItem title="ml" value={`${MeasurementSystem.getLengthInMetersOrYards(selectedRoadSection.lengthInMeters).toFixed(2)} ${MeasurementSystem.getSymbolOfLengthUnit()}`} />
                            <InformationItem title={Translate.Resources.UI_RoadSectionDetails_City} value={selectedRoadSection.municipality ?? ''} />
                        </Box>
                        <Box className="title">{Translate.Resources.UI_RoadSectionDetails_StatementDate}</Box>
                        <Box className="title">{selectedRoadSection.videoDateTime ? selectedRoadSection.videoDateTime.toLocaleDateString() : ''}</Box>
                    </Box>
                }
                <Box display="flex" flexDirection="column" className={`images ${isDisplayedDetailsOpened ? 'details-opened' : ''}`} >
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        {!isDisplayedDetailsOpened && <SectionScoreComponent selectedRoadSection={selectedRoadSection} />}
                        <Box className="navigation">
                            <Box display="flex" flexDirection="row" justifyContent="space-between" width="65px">
                                <Button className="btn-navigation" onClick={() => props.handleStepChanged(selectedImage, false)}>
                                    <FirstPageIcon fontSize="small" />
                                </Button>
                                <Button className="btn-navigation" onClick={() => props.handleImageChanged(selectedImage.previousImageId)}>
                                    <ChevronLeftIcon fontSize="small" />
                                </Button>
                            </Box>
                            <Box display="flex" flexDirection="row" width={isDisplayedDetailsOpened ? '282px' : '373px'} justifyContent="center">
                                <ButtonGroup variant="contained" aria-label="button group" className="image-ids-group">
                                    {displayedImages.map((image, index) => {
                                        return (
                                            <Button key={`image-${index}`} className={`button ${selectedImage.imageId === image.imageId ? 'selected' : ''}`} onClick={() => props.handleImageChanged(image.imageId)}>
                                                {image.displayedId}
                                            </Button>
                                        );
                                    })}
                                </ButtonGroup>
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="space-between" width="65px">
                                <Button className="btn-navigation" onClick={() => props.handleImageChanged(selectedImage.nextImageId)}>
                                    <ChevronRightIcon fontSize="small" />
                                </Button>
                                <Button className="btn-navigation" onClick={() => props.handleStepChanged(selectedImage, true)}>
                                    <LastPageIcon fontSize="small" />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="flex-end" className="image-group">
                        <ButtonGroup variant="contained" aria-label="button group" className="image-btns-group">
                            <Button className={`button ${imageDisplayedMode === ImageDisplayedMode.image ? 'selected' : ''}`} onClick={() => setImageDisplayedMode(ImageDisplayedMode.image)}>
                                <img src={iconIncognito} alt="icon incognito" />
                                {Translate.Resources.UI_RoadSectionDetails_Original}
                            </Button>
                            {role !== "CLIPLUS" && role !== "CLI" && role !== "VISU" && <Button className={`button ${imageDisplayedMode === ImageDisplayedMode.labeled ? 'selected' : ''}`} onClick={() => setImageDisplayedMode(ImageDisplayedMode.labeled)}>
                                <img src={iconInfracareCarSquare} alt="icon infracare-car-square" />
                                {Translate.Resources.UI_RoadSectionDetails_Detection}
                            </Button>}
                            {role !== "CLIPLUS" && role !== "CLI" && role !== "VISU" && <Button className={`button ${imageDisplayedMode === ImageDisplayedMode.combined ? 'selected' : ''}`} onClick={() => setImageDisplayedMode(ImageDisplayedMode.combined)}>
                                <CompareIcon />
                                {Translate.Resources.UI_RoadSectionDetails_Mix}
                            </Button>}
                        </ButtonGroup>
                    </Box>
                    <Box display="flex" flexDirection="column" className="images-box">
                        {imageDisplayedMode === ImageDisplayedMode.image &&
                            <Box className={`image ${isDisplayedDetailsOpened ? 'details-opened' : ''}`}>
                                <ImageContent
                                    url={`/api/RoadsCondition/GetImageContent?publicImageId=${selectedImage.publicImageId}`}
                                    videoDateTime={videoDateTime}
                                    handleOpenImageInNewTab={() => handleOpenImageInNewTab(`/api/RoadsCondition/GetImageContent?publicImageId=${selectedImage.publicImageId}`)}
                                />
                            </Box>
                        }
                        {hasScoreAnalysisAccess && role !== "CLIPLUS" && imageDisplayedMode === ImageDisplayedMode.labeled &&
                            <Box className={`labeled-image ${isDisplayedDetailsOpened ? 'details-opened' : ''}`}>
                                <ImageContent
                                    url={`/api/RoadsCondition/GetLabeledImageContent?publicImageId=${selectedImage.publicImageId}`}
                                    videoDateTime={videoDateTime}
                                    handleOpenImageInNewTab={() => handleOpenImageInNewTab(`/api/RoadsCondition/GetLabeledImageContent?publicImageId=${selectedImage.publicImageId}`)}
                                />
                            </Box>
                        }
                        {hasScoreAnalysisAccess && role !== "CLIPLUS" && imageDisplayedMode === ImageDisplayedMode.combined &&
                            <Box className={`combined-images ${!isDisplayedDetailsOpened ? 'details-closed' : ''}`}>
                                <Box className={`combined-image ${isDisplayedDetailsOpened ? 'details-opened' : ''}`}>
                                    <ImageContent
                                        url={`/api/RoadsCondition/GetImageContent?publicImageId=${selectedImage.publicImageId}`}
                                        videoDateTime={videoDateTime}
                                        handleOpenImageInNewTab={() => handleOpenImageInNewTab(`/api/RoadsCondition/GetImageContent?publicImageId=${selectedImage.publicImageId}`)}
                                    />
                                </Box>
                                <Box className={`combined-image ${isDisplayedDetailsOpened ? 'details-opened' : ''}`}>
                                    <ImageContent
                                        url={`/api/RoadsCondition/GetLabeledImageContent?publicImageId=${selectedImage.publicImageId}`}
                                        videoDateTime={videoDateTime}
                                        handleOpenImageInNewTab={() => handleOpenImageInNewTab(`/api/RoadsCondition/GetLabeledImageContent?publicImageId=${selectedImage.publicImageId}`)}
                                    />
                                </Box>
                            </Box>
                        }
                    </Box>
                    {hasScoreAnalysisAccess &&
                        <Box className={`anomalies ${imageDisplayedMode === ImageDisplayedMode.combined ? 'combined' : ''}`}>
                            {selectedImage.anomaliesCounters && (Array.from(selectedImage.anomaliesCounters)).map((anomaly, index) => {
                                return (
                                    <Box className="image-anomaly" display="flex" flexDirection="row" key={`image-anomaly${index}`}>
                                        <Box className="counter filled">{anomaly[1].counter}</Box>
                                        <Box className="label">{Translate.GetAnomalyTypeLabel(anomaly[0])}</Box>
                                    </Box>
                                )
                            })}
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
}