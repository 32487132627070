import { load, loadMessages } from "@progress/kendo-react-intl";
import Resources from "./Resources";
import Resources_en from "./Resources.en";
import Resources_fi from "./Resources.fi";
import Resources_fr from "./Resources.fr";

export default class Translate {

    public static Resources: Resources;

    public static SetUp = (resources: Resources, kendoMessages: any, locale: string) => {
        Translate.Resources = resources;
        loadMessages(kendoMessages, locale);
    }

    public static GetRoadTypeLabel = (roadTypeId: string): string => {
        var key = `UI_Global_RoadType_Label_${roadTypeId}`;
        var result = Translate.Resources[key];
        return result ?? `!! ${key} !!`;
    }

    public static GetAnomalyTypeLabel = (anomalyType: string): string => {
        var key = `UI_Global_AnomalyType_Label_${anomalyType}`;
        var result = Translate.Resources[key];
        return result ?? `!! ${key} !!`;
    }
}

export class Localization {

    public static locale: string;
    public static ianaTimeZoneId: string;

    public static SetUp = () => {
        var navigatorLanguage = navigator.language;

        Localization.ianaTimeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

        var resources: Resources;
        var kendoMessages: any;

        var cldrCurrencies: any;
        var cldrNumbers: any;
        var cldrDateFields: any;
        var cldrCaGregorian: any;
        var cldrTimeZoneNames: any;
        var cldrWeekData: any;

        //NOTE CMA HGA il faut importer chaque conf avec le chemin complet en dur car la technique avec substitution à l'execution
        //ex: cldrCurrencies = require(`cldr-numbers-full/main/${Localization.locale}/currencies.json`);
        //provoquera l'inclusion integrale du package/base cldr qui pèse 76Mo !!
        switch (navigatorLanguage?.toLocaleLowerCase()) {
            case 'fr':
            case 'fr-be':
            case 'fr-ca':
            case 'fr-ch':
            case 'fr-fr':
            case 'fr-lu':
            case 'fr-mc':
                resources = new Resources_fr();
                Localization.locale = 'fr';

                cldrCurrencies = require(`cldr-numbers-full/main/fr/currencies.json`);
                cldrNumbers = require(`cldr-numbers-full/main/fr/numbers.json`);
                cldrDateFields = require(`cldr-dates-full/main/fr/dateFields.json`);
                cldrCaGregorian = require(`cldr-dates-full/main/fr/ca-gregorian.json`);
                cldrTimeZoneNames = require(`cldr-dates-full/main/fr/timeZoneNames.json`);
                break;

            case 'fi':
                resources = new Resources_fi();
                Localization.locale = 'fi';

                cldrCurrencies = require(`cldr-numbers-full/main/fi/currencies.json`);
                cldrNumbers = require(`cldr-numbers-full/main/fi/numbers.json`);
                cldrDateFields = require(`cldr-dates-full/main/fi/dateFields.json`);
                cldrCaGregorian = require(`cldr-dates-full/main/fi/ca-gregorian.json`);
                cldrTimeZoneNames = require(`cldr-dates-full/main/fi/timeZoneNames.json`);
                break;

            case 'en':
            case 'en-us':
            case 'en-uk':
            default:
                resources = new Resources_en();
                Localization.locale = 'en';

                cldrCurrencies = require(`cldr-numbers-full/main/en/currencies.json`);
                cldrNumbers = require(`cldr-numbers-full/main/en/numbers.json`);
                cldrDateFields = require(`cldr-dates-full/main/en/dateFields.json`);
                cldrCaGregorian = require(`cldr-dates-full/main/en/ca-gregorian.json`);
                cldrTimeZoneNames = require(`cldr-dates-full/main/en/timeZoneNames.json`);
                break;
        }

        kendoMessages = require(`../localization/KendoRessources/${Localization.locale}.json`);
        Translate.SetUp(resources, kendoMessages, Localization.locale);

        cldrWeekData = require(`cldr-core/supplemental/weekData.json`);
        load(cldrCurrencies, cldrNumbers, cldrDateFields, cldrCaGregorian, cldrTimeZoneNames, cldrWeekData);
    }
}