import { GridCellProps } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { RoadSectionGridViewData } from '../../models/RoadSectionGridViewData';
import { TextBox, TextBoxChangeEvent } from '@progress/kendo-react-inputs';

export const CustomEditableTextCellComponent = (props: GridCellProps): JSX.Element => {
    const dataItem = props.dataItem as RoadSectionGridViewData;


    const [newValue, setNewValue] = useState<string>(dataItem.changes?.get(props.field));

    let defaultRendering: JSX.Element = null;

    const handleChange = (event: TextBoxChangeEvent, props: GridCellProps): void => {
        dataItem.inEdit = props.field;
        let newValue = event.value != null ? event.value : dataItem[props.field];

        //We need to set to null for filtering
        if (newValue === '') {
            newValue = null;
        }
        dataItem.changes.set(props.field, newValue);
        setNewValue(newValue);
    }

    if (!dataItem.inEdit || dataItem.inEdit !== props.field) {
        const oldValue = dataItem[props.field];
        if (newValue === undefined || newValue === oldValue) {
            defaultRendering = (
                <td >
                    {oldValue}
                </td>
            );
        }
        else {
            defaultRendering = (
                <td className={"field-changed"}>
                    {!newValue ? '' : newValue}
                </td>
            );
        }
    } else {
        defaultRendering = (
            <td>
                <TextBox
                    value={newValue !== undefined ? newValue : props.dataItem[props.field]}
                    onChange={(event) => handleChange(event, props)}
                    size="small"
                />

            </td>
        );
    }
    return props.render ? props.render.call(undefined, defaultRendering, props) : defaultRendering;
}