import { Box, IconButton } from "@mui/material";
import { Calendar, CalendarChangeEvent, DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { GridCellProps } from "@progress/kendo-react-grid";
import React, { useState } from 'react';
import iconCalendar from 'src/assets/icons/icon-calendar.svg';
import { ProgrammingAreaExtended } from "../../models/ProgrammingAreaExtended";

interface CustomDatePickerCellProps {
    isStartDate: boolean,
    disabled?: boolean,
    handleDateChanged?: (dataItem: ProgrammingAreaExtended) => void
}

export const CustomDatePickerCell = (props: GridCellProps & CustomDatePickerCellProps): JSX.Element => {

    const { dataItem } = props;

    const [show, setShow] = useState<boolean>(null);
    const [value, setValue] = useState<Date>(props.isStartDate ? (dataItem.startDate ?? null) : (dataItem.endtDate ?? null));

    const onCalendarChanged = (e: CalendarChangeEvent): void => {
        let value = e.target.value;
        if (props.isStartDate) {
            dataItem.startDate = value;
        }
        else {
            dataItem.endtDate = value;
        }
        setShow(false);
        setValue(value);
        props.handleDateChanged(dataItem);
    }

    const onDatePickerChanged = (e: DatePickerChangeEvent): void => {
        if (e.target.validity.valid) {
            if (props.isStartDate) {
                dataItem.startDate = e.value;
            }
            else {
                dataItem.endtDate = e.value;
            }
            setValue(e.value);
        }
    }

    const onDatePickerBlur = () => {
        props.handleDateChanged(dataItem);
    }

    const defaultRendering = (
        <td>
            <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center">
                <DatePicker
                    disabled={props.disabled}
                    toggleButton={() => <></>}
                    show={show}
                    className="date-picker"
                    format={{ year: "numeric", month: "numeric" }}
                    calendar={() => <Calendar
                        bottomView="year"
                        topView="year"
                        value={value}
                        onChange={(e) => onCalendarChanged(e)}
                    />}
                    value={value}
                    onChange={(e) => onDatePickerChanged(e)}
                    onBlur={() => onDatePickerBlur()}
                />
                <IconButton onClick={() => setShow(true)} className="icon-button" disabled={props.disabled}>
                    <img src={iconCalendar} alt="icon calendar" />
                </IconButton>
            </Box>
        </td>
    );

    return defaultRendering;
}