import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { AxiosResponse } from 'axios';
import React, { Component } from 'react';
import Translate, { Localization } from '../../../localization/Localization';
import { Currencies, getCurrencyCode } from '../../../shared/models/Currencies';
import { Point } from '../../../shared/models/Point';
import ToastService from '../../../ToastService';
import BusinessMessages from '../../../utils/BusinessMessages';
import { RouteComponentProps, withRouter } from '../../../withRouter';
import { ProjectVersion } from '../../Home/services/dataContracts/queryStack/ProjectVersion';
import { CostRatio } from '../../ProjectSettings/services/dataContracts/queryStack/CostRatio';
import { WorkPriority } from '../../ProjectSettings/services/dataContracts/queryStack/WorkPriority';
import { MergedProjectVersion } from '../../RoadsCondition/models/MergedProjectVersion';
import { RouteLocationStateModel } from '../../RoadsCondition/models/RouteLocationStateModel';
import { RoadsConditionAndScenariosShared } from '../../RoadsCondition/RoadsConditionAndScenariosShared';
import { AddScenarioRequestArgs } from '../services/dataContracts/controller/AddScenarioRequestArgs';
import { DeleteScenarioRequestArgs } from '../services/dataContracts/controller/DeleteScenarioRequestArgs';
import { DuplicateScenarioRequestArgs } from '../services/dataContracts/controller/DuplicateScenarioRequestArgs';
import { UpdateScenarioRequestArgs } from '../services/dataContracts/controller/UpdateScenarioRequestArgs';
import { Scenario } from '../services/dataContracts/queryStack/Scenario';
import { ScenariosApiClient } from '../services/ScenariosApiClient';
import { AddOrEditScenarioComponent } from './components/AddOrEditScenarioComponent';
import { ScenarioModuleDescriptionComponent } from './components/ScenarioModuleDescriptionComponent';
import { ScenariosListComponent } from './components/ScenariosListComponent';
import { ScenariosMapComponent } from './components/ScenariosMapComponent';
import './ScenariosManagementStyles.scss';

interface ScenariosManagementViewState {
    isCreateEditScenarioContentVisible: boolean,
    currencyCode: string,
    currencySymbole: string,
    scenarios: Scenario[],
    loading: boolean,
    isScenarioInEdit: boolean,
    scenarioInEdit: Scenario,
    currentMergedProject: MergedProjectVersion,
    workPriorities: WorkPriority[],
    isImpossibleScenarioMigrationDialogOpened: boolean
}

const initialState: ScenariosManagementViewState = {
    isCreateEditScenarioContentVisible: false,
    currencyCode: null,
    currencySymbole: null,
    scenarios: null,
    loading: false,
    isScenarioInEdit: false,
    scenarioInEdit: null,
    currentMergedProject: null,
    workPriorities: null,
    isImpossibleScenarioMigrationDialogOpened: false
}

export class ScenariosManagementView extends Component<RouteComponentProps, ScenariosManagementViewState> {
    _isMounted: boolean;
    projectId: string;
    projectVersionId: number;
    locationGeometry: Point;
    projectVersionsCache: Map<number, ProjectVersion>;
    mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>;
    costRatiosCache: Map<number, CostRatio[]>;

    constructor(props) {
        super(props);

        this.projectVersionsCache = new Map<number, ProjectVersion>();
        this.mergedProjectAuscultationsCache = new Map<number, MergedProjectVersion>();
        this.costRatiosCache = new Map<number, CostRatio[]>();

        this.state = initialState;
    }

    async componentDidMount() {
        this._isMounted = true;

        let locationState = this.props.location.state as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        this.projectId = locationState.projectId;
        this.projectVersionId = locationState.projectVersionId;
        this.locationGeometry = locationState.locationGeometry;

        const query = new URLSearchParams(this.props.location.search);
        const scenarioId = Number(query.get('scenarioId'));

        this.setState({
            loading: true
        });

        const apiData = await Promise.all([
            ScenariosApiClient.GetProjectCurrency(this.projectId),
            ScenariosApiClient.GetScenarios(this.projectId),
            ScenariosApiClient.GetWorkPriorities(this.projectId)
        ]);

        let currencyCode = getCurrencyCode(apiData[0].data);
        let currencySymbole = Currencies[apiData[0].data];
        let scenarios = apiData[1].data;

        let projectVersionApiCalls: Promise<MergedProjectVersion>[] = [];
        let projectVersionIds = scenarios.map(x => x.projectVersionId);
        let pvIds = new Set(projectVersionIds);
        if (!pvIds.has(this.projectVersionId)) {
            pvIds.add(this.projectVersionId);
        }
        pvIds.forEach((projectVersionId) => {
            projectVersionApiCalls.push(RoadsConditionAndScenariosShared.getMergedProject(projectVersionId, this.mergedProjectAuscultationsCache, this.projectVersionsCache))
        });

        let yearApiCalls: Promise<AxiosResponse<CostRatio[]>>[] = [];
        let years = scenarios.map(x => x.year);
        let yearsSet = new Set(years);
        yearsSet.forEach((year) => {
            yearApiCalls.push(ScenariosApiClient.GetCostRatios(this.projectId, year))
        });

        let costRatiosData = await Promise.all(yearApiCalls);
        costRatiosData.forEach((res) => {
            let data = res.data;
            if (data.length > 0) {
                this.costRatiosCache.set(data[0].year, data);
            }
        });

        await Promise.all(projectVersionApiCalls).then(() => {
            let state: ScenariosManagementViewState = { ...this.state };

            if (scenarioId) {
                let scenario = scenarios.find(x => x.scenarioId === scenarioId);
                state.isCreateEditScenarioContentVisible = true;
                state.isScenarioInEdit = true;
                state.scenarioInEdit = scenario;
            }
            state.currentMergedProject = this.mergedProjectAuscultationsCache.get(this.projectVersionId)
            state.workPriorities = apiData[2].data;
            state.currencyCode = currencyCode;
            state.currencySymbole = currencySymbole;
            state.scenarios = scenarios;
            state.loading = false;

            this.setState(state);
        });
    }

    handleCreateScenarioClicked = () => {
        this.setState({
            isCreateEditScenarioContentVisible: true,
            isScenarioInEdit: false,
            scenarioInEdit: null
        });
    }

    navigateToScenarioSectionsManagement = (scenarioId: number): void => {
        let urlRedirect = `/ScenarioSectionsManagement?scenarioId=${scenarioId}`;
        let locationState = this.props.location.state as RouteLocationStateModel;
        this.props.navigate(urlRedirect, { state: locationState });
    }

    navigateToScenarioSectionsVisualisation = (scenarioId: number): void => {
        let urlRedirect = `/ScenarioSectionsVisualisation?scenarioId=${scenarioId}`;
        let locationState = this.props.location.state as RouteLocationStateModel;
        this.props.navigate(urlRedirect, { state: locationState });
    }

    handleAddScenarioClicked = (args: AddScenarioRequestArgs): void => {
        this.setState({
            loading: true
        });

        ScenariosApiClient.AddScenario(args)
            .then(res => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }

                const scenarioId: number = data.customData;
                this.navigateToScenarioSectionsManagement(scenarioId);

                this.setState({
                    loading: false
                });
            });
    }

    handleUpdateScenarioClicked = (args: UpdateScenarioRequestArgs): void => {
        this.setState({
            loading: true
        });

        ScenariosApiClient.UpdateScenario(args)
            .then(res => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }

                this.handleNavigateAfterUpdateOrCanceledAction();
            });
    }

    handleCancelAddScenarioClicked = (): void => {
        this.handleNavigateAfterUpdateOrCanceledAction();
    }

    handleNavigateAfterUpdateOrCanceledAction = (): void => {
        const query = new URLSearchParams(this.props.location.search);
        if (query.get('scenarioId')) {
            this.props.navigate(-1);
            return;
        }

        ScenariosApiClient.GetScenarios(this.projectId)
            .then((res) => {
                let scenarios = res.data;
                this.setState({
                    isCreateEditScenarioContentVisible: false,
                    isScenarioInEdit: false,
                    scenarioInEdit: null,
                    scenarios: scenarios,
                    loading: false
                });
            })
    }

    handleOpenScenario = (scenarioId: number): void => {
        this.navigateToScenarioSectionsVisualisation(scenarioId);
    }

    handleEditScenario = (scenarioId: number, state: ScenariosManagementViewState): void => {
        let scenario = state.scenarios.find(x => x.scenarioId === scenarioId);

        this.setState({
            isCreateEditScenarioContentVisible: true,
            isScenarioInEdit: true,
            scenarioInEdit: scenario
        });
    }

    handleDeleteScenario = (scenarioId: number): void => {
        this.setState({
            loading: true
        });

        let args: DeleteScenarioRequestArgs = {
            scenarioId: scenarioId,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        ScenariosApiClient.DeleteScenario(args)
            .then(res => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }

                ScenariosApiClient.GetScenarios(this.projectId)
                    .then((res) => {
                        let scenarios = res.data;

                        this.setState({
                            loading: false,
                            scenarios: scenarios
                        });
                    });
            });
    }

    handleDuplicateScenario = (projectId: string, projectVersionId: number, projectVersionNumber: number, scenarioId: number): void => {
        this.setState({
            loading: true
        });

        let args: DuplicateScenarioRequestArgs = {
            projectId: projectId,
            projectVersionId: projectVersionId,
            projectVersionNumber: projectVersionNumber,
            scenarioId: scenarioId,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        ScenariosApiClient.DuplicateScenario(args)
            .then(res => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }

                if (data as any === false) {
                    this.setState({
                        isImpossibleScenarioMigrationDialogOpened: true,
                        loading: false
                    });
                    return;
                }

                ToastService.showSuccessToast(Translate.Resources.UI_ScenariosManagementView_ScenarioWasSuccessfullyMigrated);

                ScenariosApiClient.GetScenarios(this.projectId)
                    .then((res) => {
                        let scenarios = res.data;

                        this.setState({
                            loading: false,
                            scenarios: scenarios
                        });
                    });
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const state = this.state;

        return (
            <Box className="scenarios">
                <Box className="scenarios-content">
                    {state.isCreateEditScenarioContentVisible ?
                        (<AddOrEditScenarioComponent projectId={this.projectId}
                            projectVersionId={this.projectVersionId}
                            currency={state.currencyCode}
                            inEdit={state.isScenarioInEdit}
                            scenarioInEdit={state.scenarioInEdit}
                            handleAddScenarioClicked={this.handleAddScenarioClicked}
                            handleUpdateScenarioClicked={this.handleUpdateScenarioClicked}
                            handleCancel={() => this.handleCancelAddScenarioClicked()} />) :
                        (state.scenarios ?
                            (state.scenarios.length > 0 ?
                                (<ScenariosListComponent currentProjectVersionId={this.projectVersionId}
                                    scenariosList={state.scenarios}
                                    currency={state.currencySymbole}
                                    workPriorities={state.workPriorities}
                                    mergedProjectAuscultationsCache={this.mergedProjectAuscultationsCache}
                                    projectVersionsCache={this.projectVersionsCache}
                                    costRatiosCache={this.costRatiosCache}
                                    handleCreateScenarioClicked={this.handleCreateScenarioClicked}
                                    handleOpenScenario={this.handleOpenScenario}
                                    handleEditScenario={(scenarioId: number) => this.handleEditScenario(scenarioId, state)}
                                    handleDeleteScenario={this.handleDeleteScenario}
                                    handleDuplicateScenario={this.handleDuplicateScenario}
                                />) :
                                (<ScenarioModuleDescriptionComponent handleCreateScenarioClicked={this.handleCreateScenarioClicked} />)
                            ) : '')
                    }
                </Box>
                {this.locationGeometry &&
                    <ScenariosMapComponent locationGeometry={this.locationGeometry} currentMergedProject={state.currentMergedProject} loading={state.loading} />
                }
                {state.isImpossibleScenarioMigrationDialogOpened &&
                    <Dialog id="impossible-scenario-migration-dialog" open={state.isImpossibleScenarioMigrationDialogOpened}>
                        <DialogTitle className="title-icon">
                            <IconButton onClick={() => {
                                this.setState({
                                    isImpossibleScenarioMigrationDialogOpened: false
                                });
                            }}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <Box display="flex" flexDirection="row" className="title" alignItems="center" justifyContent="center">
                                <ReplayIcon className="migrate-icon" />
                                <div>{Translate.Resources.UI_Scenarios_ScenarioCard_Menu_MigrateScenarioDialog_StatementMigration}</div>
                            </Box>
                            <Box>
                                <Box className="text">
                                    {Translate.Resources.UI_Scenarios_AutomaticMigrationImpossible}
                                </Box>
                                <Box className="text">
                                    {Translate.Resources.UI_Scenarios_NecessaryToCreateANewScenario}
                                </Box>
                            </Box>
                        </DialogContent>
                    </Dialog>
                }
            </Box>
        );
    }
}

export default React.forwardRef(withRouter(ScenariosManagementView));
