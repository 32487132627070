import SaveIcon from '@mui/icons-material/Save';
import { Box, Button } from "@mui/material";
import { CompositeFilterDescriptor, DataResult, FilterDescriptor, orderBy as sortOrderBy, process, SortDescriptor, State } from "@progress/kendo-data-query";
import { Grid, GridCellProps, GridColumn, GridColumnMenuProps, GridColumnMenuSort, GridColumnProps, GridColumnReorderEvent, GridColumnResizeEvent, GridDataStateChangeEvent, GridHeaderCellProps, GridPageChangeEvent, GridRowProps, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { cloneDeep } from 'lodash';
import React, { useEffect, useRef, useState } from "react";
import Translate, { Localization } from '../../../../localization/Localization';
import { MetersOrYardsRoundedCell } from '../../../../shared/GridUtils/GridUtils';
import { getBooleanList, getEnvironmentList, getHierarchyList, getManagerList, getTrafficList } from "../../../../shared/models/Attributes/AttributesLabels";
import { UserPermissionIds } from "../../../../shared/models/UserPermissionIds";
import { MeasurementSystem } from "../../../../utils/MeasurementSystem";
import { AppModule, LocalStorage } from "../../../../utils/Storage";
import { getHiddenIsSelectedForSort, RoadSectionGridViewData } from "../../models/RoadSectionGridViewData";
import { ColumnMenu } from './ColumnMenu';
import { CustomBoleanCellComponent } from "./CustomBooleanCellComponent";
import { CustomCheckboxCell } from "./CustomCheckboxCell";
import { CustomCheckboxHeaderCell } from "./CustomCheckboxHeaderCell";
import { CustomEditableNumericCellComponent } from "./CustomEditableNumericCellComponent";
import { CustomEditableTextCellComponent } from "./CustomEditableTextCellComponent";
import { CustomEnvironmentCellComponent } from "./CustomEnvironmentCellComponent";
import { CustomHierarchyCellComponent } from "./CustomHierarchyCellComponent";
import { CustomManagerCellComponent } from "./CustomManagerCellComponent";
import { CustomTrafficCellComponent } from "./CustomTrafficCellComponent";
import { EditedFieldModel } from "./EditedFieldModel";
import './HighwaysGridComponent.scss';
import { SizingUtilities } from "./SizingUtilities";

interface HighwaysGridComponentProps {
    roadSectionsAttributes: RoadSectionGridViewData[],
    onSelectedSectionChange: (selectedSectionsId: number[]) => void,
    onFilterSectionChange: (filteredSectionsId: number[], isGridHasActiveFilters: boolean) => void,
    onValidateUpdateSectionAttributes: (updatedRoadSectionGridViewData: RoadSectionGridViewData[]) => Promise<void>
    selectedSectionsId: number[],
    userPermissions: string[],
    filteredSectionsIdFromSearchText: number[],
    searchText: string
}

const ModuleKey = AppModule.Highways;
const highwaysGridName = 'highways';
const DataItemKey = 'roadSectionId';
const editFieldKey = "inEdit";
let initialSort: SortDescriptor[] = [{ field: 'roadSectionId', dir: 'desc' }];

function useForceUpdate() {
    const [, setTick] = React.useState(0);
    const update = React.useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}

export const HighwaysGridComponent = (props: HighwaysGridComponentProps) => {

    const columns = [
        { id: 'roadSectionId', field: 'roadSectionId', title: Translate.Resources.UI_Highways_Grid_Section },
        { id: 'roadLabel', field: 'roadLabel', title: Translate.Resources.UI_Highways_Grid_Road },
        { id: 'lengthInMeters', field: 'lengthInMeters', title: `${Translate.Resources.UI_Highways_Grid_Linear} (${MeasurementSystem.getSymbolOfLengthUnit()})` },
        { id: 'widthInMeters', field: 'widthInMeters', title: `${Translate.Resources.UI_Highways_Grid_Width} (${MeasurementSystem.getSymbolOfLengthUnit()})` },
        { id: 'district', field: 'district', title: Translate.Resources.UI_Highways_Grid_District },
        { id: 'municipality', field: 'municipality', title: Translate.Resources.UI_Highways_Grid_City },
        { id: 'roadType', field: 'roadType', title: Translate.Resources.UI_Highways_Grid_RoadType },
        { id: 'hierarchy', field: 'hierarchy', title: Translate.Resources.UI_Highways_Grid_Hierarchy },
        { id: 'traffic', field: 'traffic', title: Translate.Resources.UI_Highways_Grid_Traffic },
        { id: 'environment', field: 'environment', title: Translate.Resources.UI_Highways_Grid_Environment },
        { id: 'manager', field: 'manager', title: Translate.Resources.UI_Highways_Grid_Manager },
        { id: 'border', field: 'border', title: Translate.Resources.UI_Highways_Grid_Border },
        { id: 'bus', field: 'bus', title: Translate.Resources.UI_Highways_Grid_Bus },
        { id: 'bikeLase', field: 'bikeLase', title: Translate.Resources.UI_Highways_Grid_BikeLase },
        { id: 'ditch', field: 'ditch', title: Translate.Resources.UI_Highways_Grid_Ditch },
        { id: 'side', field: 'side', title: Translate.Resources.UI_Highways_Grid_Side },
        { id: 'collaborativeDevelopmentZone', field: 'collaborativeDevelopmentZone', title: Translate.Resources.UI_Highways_Grid_CollaborativeDevelopmentZone },
        { id: 'osmRoadLabel', field: 'osmRoadLabel', title: Translate.Resources.UI_Highways_Grid_Osm_Road_Label }
    ];

    let preventExit: boolean;
    let preventExitTimeout: ReturnType<typeof setTimeout>;
    let blurTimeout: ReturnType<typeof setTimeout>;

    const canEditGrid: boolean = props.userPermissions && props.userPermissions.filter(e => e === UserPermissionIds.editHighways).length > 0;

    const [roadSectionGridViewData, setRoadSectionGridViewData] = useState<RoadSectionGridViewData[]>([]);
    const [sort, setSort] = useState<SortDescriptor[]>(initialSort);
    const [skip, setSkip] = useState<number>(0);
    const [dataState, setDataState] = useState({
        sort: initialSort
    });
    const [editField, setEditField] = useState<EditedFieldModel>(null);
    const [hasDataChanged, setHasDataChanged] = useState<boolean>(false);
    const [resultState, setResultState] = useState<DataResult>();

    let visibleColumnsStorage = LocalStorage.GetItem(ModuleKey, "visibleColumns");
    let visibleColumnsSet = new Set<string>(columns.map(x => x.field));
    let visibleColumnsArray = cloneDeep(columns);

    if (visibleColumnsStorage) {
        let visibleColumnsStorageArray = JSON.parse(visibleColumnsStorage);
        visibleColumnsSet = new Set<string>(visibleColumnsStorageArray);

        let displayedColumns = [];
        visibleColumnsArray.forEach((column) => {
            if (visibleColumnsSet.has(column.field)) {
                displayedColumns.push(column);
            }
        });

        visibleColumnsArray = displayedColumns;
    }

    const [visibleColumns, setVisibleColumns] = useState<Set<string>>(visibleColumnsSet);
    const [columnsState, setColumnsState] = useState<GridColumnProps[]>(visibleColumnsArray);

    const gridRef = useRef<Grid>();

    const getSectionsIdFromDataResult = (dataResult: DataResult): number[] =>
        dataResult.data.map(e => e.roadSectionId);

    const translateDataState = (dataState) => {
        let newDataState = cloneDeep(dataState);

        if (newDataState.filter && newDataState.filter.filters && newDataState.filter.filters[0]) {

            let newRootFilter = newDataState.filter.filters.map(rootFilter => {
                let newFilters = rootFilter.filters.map(filter => {

                    if (filter.value === Translate.Resources.UI_Highways_Grid_Value_Empty) {
                        filter.value = null;
                    }

                    else if (filter.field === "hierarchy") {
                        filter.value = getHierarchyList().find((c) => c.text === filter.value)?.value
                    }
                    else if (filter.field === "traffic") {
                        filter.value = getTrafficList().find((c) => c.text === filter.value)?.value
                    }
                    else if (filter.field === "environment") {
                        filter.value = getEnvironmentList().find((c) => c.text === filter.value)?.value
                    }
                    else if (filter.field === "manager") {
                        filter.value = getManagerList().find((c) => c.text === filter.value)?.value
                    }
                    else if (filter.field === "border"
                        || filter.field === "bus"
                        || filter.field === "bikeLase"
                        || filter.field === "ditch"
                        || filter.field === "side") {
                        filter.value = getBooleanList().find((c) => c.text === filter.value)?.value
                    }

                    return filter;
                });
                rootFilter.filters = newFilters;
                return rootFilter;
            });

            newDataState.filter.filters = newRootFilter;
        }
        return newDataState;
    }

    const forceUpdate = useForceUpdate();

    const processWithGroups = (data, dataState: State) => {
        //localize Data
        let localizedDataState = translateDataState(dataState);
        let selectedSectionsId: number[] = props.selectedSectionsId;

        let filteredDataState = cloneDeep(localizedDataState);
        filteredDataState.take = undefined;

        const filteredDataResult = process(data, filteredDataState);

        let sectionsIdDisplayed = getSectionsIdFromDataResult(filteredDataResult);
        selectedSectionsId.forEach(e => {
            if (!sectionsIdDisplayed.includes(e)) {
                sectionsIdDisplayed.push(e);
            }
        })

        // Apply virtual scrolling and Order
        // we need to process again whithout filtering to apply order.
        let filterAndSelectedSections: RoadSectionGridViewData[] = [];
        let nonFilteredDataState = cloneDeep(localizedDataState);
        nonFilteredDataState.take = undefined;
        nonFilteredDataState.filter = undefined;

        filterAndSelectedSections = data.filter(e => sectionsIdDisplayed.includes(e.roadSectionId));
        const localizedDataResultWithSelection = process(filterAndSelectedSections, nonFilteredDataState);

        return localizedDataResultWithSelection;
    };

    const columnMenuFilter = (columnsprops: GridColumnMenuProps, field: string, enableFilter: boolean): JSX.Element =>
        <ColumnMenu columnsprops={columnsprops}
            data={roadSectionGridViewData}
            field={field}
            enableFilter={enableFilter}
            columns={columns}
            columnsState={columnsState}
            onColumnsChange={onColumnsChange}
        />

    const onColumnsChange = (cols: GridColumnProps[]): void => {
        setColumnsState(cols);

        let visibleColumns = cols.map(x => x.field);
        setVisibleColumns(new Set(visibleColumns));
        LocalStorage.SetItem(ModuleKey, "visibleColumns", JSON.stringify(visibleColumns));
    }

    useEffect(() => {
        //keep row selection
        let selectedRoadSectionsId = props.selectedSectionsId;

        let gridViewData = cloneDeep(props.roadSectionsAttributes);

        gridViewData.forEach(e => {
            if (selectedRoadSectionsId.includes(e.roadSectionId)) {
                e.isSelected = true;
                e.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(true);
            }
        });

        setRoadSectionGridViewData(gridViewData);

        const newResultState = processWithGroups(
            gridViewData,
            dataState
        );

        setResultState(newResultState);
    }, [props.roadSectionsAttributes]);

    useEffect(() => {
        let newDataState = cloneDeep(dataState);
        newDataState.sort = sort;
        const newResultState = processWithGroups(
            roadSectionGridViewData,
            newDataState
        );

        setDataState({ ...dataState, sort: sort });
        setResultState(newResultState);
    }, [sort]);

    useEffect(() => {
        let gridViewData = cloneDeep(roadSectionGridViewData);
        gridViewData.forEach(roadSectionAttribute => {
            let select: boolean = props.selectedSectionsId.includes(roadSectionAttribute.roadSectionId);
            roadSectionAttribute.isSelected = select;
            roadSectionAttribute.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(select);
        });

        setRoadSectionGridViewData(gridViewData);

        const newResultState = processWithGroups(
            gridViewData,
            dataState
        );

        setResultState(newResultState);
    }, [props.selectedSectionsId]);

    // We use the function isColumnActive as a workaround because of a bug with kendo react with "GridColumnMenuSort.active()"
    // The Issue : https://github.com/telerik/kendo-react/issues/1167
    // The workAround : https://stackblitz.com/edit/react-skkquv-ytekuc?file=app%2Fmain.tsx
    const isColumnActive = (field: string, dataState: State) => {
        let hasFilter = false;

        if (dataState.filter && dataState.filter.filters && dataState.filter.filters[0]) {
            hasFilter = dataState.filter.filters.some((rootFilter) => ((rootFilter as CompositeFilterDescriptor).filters[0] as FilterDescriptor).field === field);
        }

        let isSorting = GridColumnMenuSort.active(field, dataState.sort);
        return (
            hasFilter ||
            isSorting
        );
    };

    const onColumnResizeHandler = (event: GridColumnResizeEvent): void => {
        LocalStorage.SetGridColumnWidth(
            ModuleKey,
            highwaysGridName,
            event.columns.find(c => c.id === event.targetColumnId).field,
            event.newWidth);
    }

    const onDataStateChange = (event: GridDataStateChangeEvent) => {
        const newResultState = processWithGroups(
            roadSectionGridViewData,
            event.dataState
        );

        setDataState(event.dataState);
        setResultState(newResultState);

        //Get all filtered Data whithout paging
        let dataStateWithoutPaging = translateDataState(event.dataState);
        dataStateWithoutPaging.take = undefined;

        let filteredSectionsIds = process(roadSectionGridViewData, dataStateWithoutPaging).data.map(e => e.roadSectionId);

        props.onFilterSectionChange(filteredSectionsIds, event.dataState?.filter?.filters?.length > 0);
    }

    const onCheckboxSelected = (roadSectionId: number, checked: boolean) => {
        let newSelectedState = cloneDeep(props.selectedSectionsId) as number[];

        if (checked) {
            newSelectedState.push(roadSectionId);
        }
        else {
            newSelectedState = newSelectedState.filter(e => e !== roadSectionId);
        }
        props.onSelectedSectionChange(newSelectedState);

        let gridViewData = cloneDeep(roadSectionGridViewData);
        let sectionAttributeIndex = gridViewData.findIndex(e => e.roadSectionId === roadSectionId);
        gridViewData[sectionAttributeIndex].isSelected = checked;
        gridViewData[sectionAttributeIndex].hiddenIsSelectedForSort = getHiddenIsSelectedForSort(checked);

        setRoadSectionGridViewData(gridViewData);

        const newResultState = processWithGroups(
            gridViewData,
            dataState
        );

        setResultState(newResultState);
    }

    const onHeaderSelectionChange = (checked: boolean) => {
        let sectionToSelect: number[];

        let gridViewData = cloneDeep(roadSectionGridViewData);

        let sectionAlreadySelected: number[] = gridViewData.filter(e => e.isSelected).map(e => e.roadSectionId);

        if (checked) {
            //Get all filtered Data whithout paging
            let dataStateWithoutPaging = translateDataState(dataState);
            dataStateWithoutPaging.take = undefined;

            if (gridViewData && props.searchText?.length > 2) {
                sectionToSelect = process(gridViewData, dataStateWithoutPaging).data.filter(x => props.filteredSectionsIdFromSearchText.includes(x.roadSectionId)).map(e => e.roadSectionId);
            }
            else {
                sectionToSelect = process(gridViewData, dataStateWithoutPaging).data.map(e => e.roadSectionId);
            }

            sectionAlreadySelected.forEach(e => sectionToSelect.push(e));
        }
        else {
            sectionToSelect = [];
        }

        props.onSelectedSectionChange(sectionToSelect);

        gridViewData.forEach(e => {
            e.isSelected = false;
            e.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(false);
        });

        if (checked) {
            sectionToSelect.forEach(e => {
                let sectionAttribute = gridViewData.filter(x => x.roadSectionId === e)[0];
                sectionAttribute.isSelected = checked;
                sectionAttribute.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(checked);
            });
        }

        setRoadSectionGridViewData(gridViewData);

        const newResultState = processWithGroups(
            gridViewData,
            dataState
        );

        setResultState(newResultState);
    }

    const onSortChange = (e: GridSortChangeEvent): void => {
        gridRef.current.scrollIntoView({ rowIndex: 0 });
        setSkip(0);
        setSort(e.sort);
    }

    const onColumnRorderHandler = (event: GridColumnReorderEvent): void => {
        LocalStorage.SetGridColumnsOrderIndexes(ModuleKey, highwaysGridName, event.columns);
        forceUpdate();
    }

    const getHighwaysGridOrderIndexColumn = (propName: string, defaultIndex: number): number => {
        return LocalStorage.GetGridColumnOrderIndex(ModuleKey, highwaysGridName, propName, defaultIndex);
    }

    const getHighwaysGridWidth = (fieldName: string, columnWidth: number): number => {
        return LocalStorage.GetGridColumnWidth(ModuleKey, highwaysGridName, fieldName, columnWidth);
    }

    const defaultGridProps = (field: string, orderIndex: number, width?: number, enableFilter: boolean = true, editable: boolean = true): GridColumnProps => {
        let defaultProps: GridColumnProps;

        let className: string;
        className = isColumnActive(field, dataState) ? "k-filterable-active " : "";
        className += editable ? "" : "not-editable ";

        defaultProps = {
            field: field,
            headerClassName: className,
            orderIndex: getHighwaysGridOrderIndexColumn(field, orderIndex),
            width: getHighwaysGridWidth(field, width),
            columnMenu: (props: GridColumnMenuProps) => columnMenuFilter(props, field, enableFilter),
            minResizableWidth: field === "isSelected" ? 49 : 90,
            editable: editable
        };

        return defaultProps;
    }

    const onCheckboxSortChange = (sortItems: SortDescriptor[]): void => {
        gridRef.current.scrollIntoView({ rowIndex: 0 });
        setSkip(0);
        setSort(sortItems);
    }

    const enterEdit = (dataItem: RoadSectionGridViewData, field: string) => {
        if (!canEditGrid)
            return

        if (dataItem.inEdit && field === editField.field && editField.id === dataItem.roadSectionId)
            return;

        exitEdit();
        dataItem.inEdit = field;
        setEditField({
            field: field,
            id: dataItem.roadSectionId
        });

        let gridViewData = cloneDeep(roadSectionGridViewData);

        let sectionAttributeIndex = gridViewData.findIndex(e => e.roadSectionId === dataItem.roadSectionId);
        gridViewData[sectionAttributeIndex].inEdit = field;

        setRoadSectionGridViewData(gridViewData);

        const newResultState = processWithGroups(
            gridViewData,
            dataState
        );

        setResultState(newResultState);
    };

    const exitEdit = () => {
        let inEditCell: string;
        let inEditChanges: Map<string, string>;
        let hasDataChanged: boolean = false;

        roadSectionGridViewData.forEach(dataItem => {
            checkDataValidity(dataItem)
            if (dataItem.changes.size > 0)
                hasDataChanged = true;

            if (dataItem.inEdit !== undefined) {
                inEditCell = dataItem.inEdit;
                if (dataItem.isSelected) {
                    inEditChanges = dataItem.changes;
                }
            }
            dataItem.inEdit = undefined;
        });

        setHasDataChanged(hasDataChanged);
        setEditField(null);

        //Update all Selected Cells
        if (inEditCell && inEditChanges && inEditChanges.size > 0) {
            props.selectedSectionsId.forEach(id => {
                let changes = inEditChanges.get(inEditCell);
                if (changes !== undefined) {
                    roadSectionGridViewData.find(e => e.roadSectionId === id).changes.set(inEditCell, changes);
                }
            });
        }
    };

    const rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, dataItem: GridRowProps): React.ReactElement<HTMLTableRowElement> => {
        const trProps = {
            ...trElement.props,
            onMouseDown: () => {
                preventExit = true;
                clearTimeout(preventExitTimeout);
                preventExitTimeout = setTimeout(() => { preventExit = undefined; });
            },
            onBlur: () => {
                clearTimeout(blurTimeout);
                if (!preventExit) {
                    blurTimeout = setTimeout(() => { exitEdit(); });
                }
            },
            onFocus: () => { clearTimeout(blurTimeout); }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    const cellRender = (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps): React.ReactElement<HTMLTableCellElement> => {
        const dataItem = cellProps.dataItem;
        const rowType = cellProps.rowType;
        const field: string = cellProps.field;

        if (tdElement && tdElement.props.children && rowType === "groupFooter") {
            let children = <span></span>
            return React.cloneElement(tdElement, tdElement.props, children);
        }

        if (!tdElement)
            return tdElement;

        const additionalProps = (dataItem.inEdit && (cellProps.field === dataItem.inEdit)) ?
            {
                ref: (td: any) => {
                    const input = td && td.querySelector('input');
                    if (!input || (input === document.activeElement)) { return; }
                    if (input.type === 'checkbox') {
                        input.focus();
                    } else {
                        input.select();
                    }
                }
            } : {
                onClick: () => { enterEdit(dataItem, field); }
            };
        return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children);
    }

    const onCancelChange = () => {
        let gridViewData = cloneDeep(roadSectionGridViewData);
        gridViewData.forEach(e => e.changes = new Map());

        setRoadSectionGridViewData(gridViewData);

        const newResultState = processWithGroups(
            gridViewData,
            dataState
        );

        setHasDataChanged(false);
        setResultState(newResultState);
    }

    const onValidateChange = () => {
        let updatedSections = roadSectionGridViewData.filter(e => e.changes.size !== 0);

        if (updatedSections.length > 0) {
            props.onValidateUpdateSectionAttributes(updatedSections);
            setHasDataChanged(false);
        }
    }

    const getDisplayedData = (): RoadSectionGridViewData[] => {
        let displayedData: RoadSectionGridViewData[];
        let localizedDataState = translateDataState(dataState);

        let filteredDataState = cloneDeep(localizedDataState);
        filteredDataState.take = undefined;

        let gridViewData = cloneDeep(roadSectionGridViewData);

        const filteredDataResult = process(gridViewData, filteredDataState);
        displayedData = filteredDataResult.data;

        if (props.searchText?.length > 2) {
            displayedData = displayedData.filter(x => props.filteredSectionsIdFromSearchText.includes(x.roadSectionId) || x.isSelected === true);
        }

        return displayedData;
    }

    const pageChange = (event: GridPageChangeEvent): void => {
        setSkip(event.page.skip);
    }

    const checkDataValidity = (attribute: RoadSectionGridViewData) => {
        if (attribute.changes.size === 0)
            return;

        //Check if empty or white space
        if (attribute.changes.has("roadLabel") && (!attribute.changes.get("roadLabel") || attribute.changes.get("roadLabel").match(/^ *$/) !== null)) {
            attribute.changes.delete("roadLabel");
        }

        if (attribute.changes.has("municipality") && (!attribute.changes.get("municipality") || attribute.changes.get("municipality").match(/^ *$/) !== null)) {
            attribute.changes.delete("municipality");
        }
    }

    let gridHeightGapFromWindow: number = SizingUtilities.sectionsGridHeightGapFromWindow();
    let gridHeight: number = SizingUtilities.computeGridHeight(gridHeightGapFromWindow);
    let rowHeight: number = SizingUtilities.rowHeight;
    let gridPageSize: number = SizingUtilities.computeGridPageSize(gridHeight, rowHeight);

    let data = resultState?.data;

    if (data && props.searchText?.length > 2) {
        data = data.filter(x => props.filteredSectionsIdFromSearchText.includes(x.roadSectionId) || x.isSelected === true);
    }

    if ((data && skip > data.length) || isNaN(skip)) {
        setSkip(0);
    }

    let dataGrid = data ? sortOrderBy(data, sort).slice(skip, skip + gridPageSize) : [];

    return (
        <Box width={"100%"} height={"100%"} display="flex" flexDirection="column" alignItems="center" justifyContent={"center"}>
            <Box height={"90%"} width={"100%"}>
                <LocalizationProvider language={Localization.locale} >
                    <IntlProvider locale={Localization.locale}>
                        <Grid ref={gridRef}
                            style={{ height: '100%', maxHeight: '100%', borderBottom: 'none' }}
                            sortable={true}
                            resizable={true}
                            reorderable={true}
                            scrollable="virtual"
                            data={dataGrid}
                            total={data?.length}
                            skip={skip}
                            {...dataState}
                            expandField="expanded"
                            selectedField={'isSelected'}
                            onDataStateChange={onDataStateChange}
                            onColumnResize={onColumnResizeHandler}
                            onColumnReorder={onColumnRorderHandler}
                            onSortChange={onSortChange}
                            cellRender={cellRender}
                            rowRender={rowRender}
                            className="table-highways-grid"
                            size={'small'}
                            pageSize={gridPageSize}
                            dataItemKey={DataItemKey}
                            rowHeight={40}
                            sort={sort}
                            editField={canEditGrid ? editFieldKey : ""}
                            onPageChange={pageChange}
                        >
                            <GridColumn
                                field="isSelected"
                                {...defaultGridProps("isSelected", 0, 49, false, false)}
                                cell={(cellProps: GridCellProps) => <CustomCheckboxCell {...cellProps} onCheckboxSelected={onCheckboxSelected} />}
                                headerCell={(cellProps: GridHeaderCellProps) => <CustomCheckboxHeaderCell {...cellProps}
                                    sortingField={"hiddenIsSelectedForSort"}
                                    displayedRoadSectionAttributes={getDisplayedData()}
                                    onHeaderSelectionChange={onHeaderSelectionChange}
                                    onCheckboxSortChange={onCheckboxSortChange}
                                    sort={sort} />}
                            />
                            {visibleColumns.has("roadSectionId") && <GridColumn {...defaultGridProps("roadSectionId", 1, 117, true, false)} title={Translate.Resources.UI_Highways_Grid_Section} />}
                            {visibleColumns.has("roadLabel") && <GridColumn {...defaultGridProps("roadLabel", 2, 109)} title={Translate.Resources.UI_Highways_Grid_Road} headerSelectionValue={true} cell={(props: GridCellProps) => <CustomEditableTextCellComponent {...props} />} />}
                            {visibleColumns.has("lengthInMeters") && <GridColumn {...defaultGridProps("lengthInMeters", 3, 118, false, false)} title={`${Translate.Resources.UI_Highways_Grid_Linear} (${MeasurementSystem.getSymbolOfLengthUnit()})`} cell={MetersOrYardsRoundedCell} />}
                            {visibleColumns.has("widthInMeters") && <GridColumn {...defaultGridProps("widthInMeters", 4, 115, false)} title={`${Translate.Resources.UI_Highways_Grid_Width} (${MeasurementSystem.getSymbolOfLengthUnit()})`} cell={(props: GridCellProps) => <CustomEditableNumericCellComponent {...props} />} />}
                            {visibleColumns.has("district") && <GridColumn {...defaultGridProps("district", 5, 135)} title={Translate.Resources.UI_Highways_Grid_District} cell={(props: GridCellProps) => <CustomEditableTextCellComponent {...props} />} />}
                            {visibleColumns.has("municipality") && <GridColumn {...defaultGridProps("municipality", 6, 155)} title={Translate.Resources.UI_Highways_Grid_City} cell={(props: GridCellProps) => <CustomEditableTextCellComponent {...props} />} />}
                            {visibleColumns.has("roadType") && <GridColumn {...defaultGridProps("roadType", 7, 129, true, false)} title={Translate.Resources.UI_Highways_Grid_RoadType} />}
                            {visibleColumns.has("hierarchy") && <GridColumn {...defaultGridProps("hierarchy", 8, 129)} title={Translate.Resources.UI_Highways_Grid_Hierarchy} cell={(props: GridCellProps) => <CustomHierarchyCellComponent {...props} exitEdit={exitEdit} />} />}
                            {visibleColumns.has("traffic") && <GridColumn {...defaultGridProps("traffic", 9, 126)} title={Translate.Resources.UI_Highways_Grid_Traffic} cell={(props: GridCellProps) => <CustomTrafficCellComponent {...props} exitEdit={exitEdit} />} />}
                            {visibleColumns.has("environment") && <GridColumn {...defaultGridProps("environment", 10, 164)} title={Translate.Resources.UI_Highways_Grid_Environment} cell={(props: GridCellProps) => <CustomEnvironmentCellComponent {...props} exitEdit={exitEdit} />} />}
                            {visibleColumns.has("manager") && <GridColumn {...defaultGridProps("manager", 11, 150)} title={Translate.Resources.UI_Highways_Grid_Manager} cell={(props: GridCellProps) => <CustomManagerCellComponent {...props} exitEdit={exitEdit} />} />}
                            {visibleColumns.has("border") && <GridColumn {...defaultGridProps("border", 12, 133)} title={Translate.Resources.UI_Highways_Grid_Border} cell={(props: GridCellProps) => <CustomBoleanCellComponent {...props} exitEdit={exitEdit} />} />}
                            {visibleColumns.has("bus") && <GridColumn {...defaultGridProps("bus", 13, 90)} title={Translate.Resources.UI_Highways_Grid_Bus} cell={(props: GridCellProps) => <CustomBoleanCellComponent {...props} exitEdit={exitEdit} />} />}
                            {visibleColumns.has("bikeLase") && <GridColumn {...defaultGridProps("bikeLase", 14, 166)} title={Translate.Resources.UI_Highways_Grid_BikeLase} cell={(props: GridCellProps) => <CustomBoleanCellComponent {...props} exitEdit={exitEdit} />} />}
                            {visibleColumns.has("ditch") && <GridColumn {...defaultGridProps("ditch", 15, 113)} title={Translate.Resources.UI_Highways_Grid_Ditch} cell={(props: GridCellProps) => <CustomBoleanCellComponent {...props} exitEdit={exitEdit} />} />}
                            {visibleColumns.has("side") && <GridColumn {...defaultGridProps("side", 16, 147)} title={Translate.Resources.UI_Highways_Grid_Side} cell={(props: GridCellProps) => <CustomBoleanCellComponent {...props} exitEdit={exitEdit} />} />}
                            {visibleColumns.has("collaborativeDevelopmentZone") && <GridColumn {...defaultGridProps("collaborativeDevelopmentZone", 17, 100)} title={Translate.Resources.UI_Highways_Grid_CollaborativeDevelopmentZone} cell={(props: GridCellProps) => <CustomEditableTextCellComponent {...props} />} />}
                            {visibleColumns.has("osmRoadLabel") && <GridColumn {...defaultGridProps("osmRoadLabel", 18, 147, true, false)} title={Translate.Resources.UI_Highways_Grid_Osm_Road_Label} />}
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider >
            </Box>
            <Box className="grid-highways-bottom-bar" display="flex" flexDirection="row" justifyContent="flex-end" alignItems="end" >
                <Button className="btn-secondary cancel-button" onClick={onCancelChange} disabled={!canEditGrid || !hasDataChanged} >
                    {Translate.Resources.UI_Highways_Grid_Button_Cancel}
                </Button>
                <Button className="btn-primary validate-button" onClick={onValidateChange} disabled={!canEditGrid || !hasDataChanged}>
                    <SaveIcon className="btn-icon" />
                    {Translate.Resources.UI_Highways_Grid_Button_Validate}
                </Button>
            </Box>
        </Box>
    )
}
