import { Box } from '@mui/material';
import { data } from 'azure-maps-control';
import React, { Component } from 'react';
import { ProgrammingStatus } from '../../shared/components/ActionsMenu/models/ProgrammingStatus';
import { PageLoaderComponent } from '../../shared/components/PageLoader/PageLoaderComponent';
import { Currencies } from '../../shared/models/Currencies';
import { MeasurementSystemType } from '../../shared/models/MeasurementSystemType';
import { Point } from '../../shared/models/Point';
import { MeasurementSystem } from '../../utils/MeasurementSystem';
import { RouteComponentProps, withRouter } from '../../withRouter';
import { ProjectVersion } from '../Home/services/dataContracts/queryStack/ProjectVersion';
import { Programming } from '../Programmings/services/dataContracts/queryStack/Programming';
import { MergedProjectVersion } from '../RoadsCondition/models/MergedProjectVersion';
import { RouteLocationStateModel } from '../RoadsCondition/models/RouteLocationStateModel';
import { RoadsConditionAndScenariosShared } from '../RoadsCondition/RoadsConditionAndScenariosShared';
import { ActionsMenuComponent } from './components/ActionsMenuComponent';
import { FollowUpWorksMapComponent } from './components/FollowUpWorksMapComponent';
import { RoadWorksInformationDrawer } from './components/RoadWorksInformationDrawer';
import './FollowUpWorksStyles.scss';
import { AreaShapeProps } from './models/AreaShapeProps';
import { FollowUpWorksApiClient } from './services/FollowUpWorksApiClient';

interface FollowUpWorksViewState {
    loading: boolean,
    mergedProject: MergedProjectVersion,
    programmings: Programming[],
    activeQualities: Set<number>,
    currentMeasurementSystemType: MeasurementSystemType,
    isRoadWorksInformationDrawerOpened: boolean,
    selectedAreas: Map<number, AreaShapeProps>,
    currencySymbole: string,
    isAddressIconPinOnMap: boolean,
    addressIconPinPositionOnMap: data.Position,
    selectedProgrammings: string[],
    selectedYears: number[],
    activeStatus: Set<ProgrammingStatus>
}

const initialState: FollowUpWorksViewState = {
    loading: false,
    mergedProject: null,
    programmings: null,
    activeQualities: new Set<number>([]),
    currentMeasurementSystemType: null,
    isRoadWorksInformationDrawerOpened: false,
    selectedAreas: new Map<number, AreaShapeProps>(),
    currencySymbole: null,
    isAddressIconPinOnMap: false,
    addressIconPinPositionOnMap: null,
    selectedProgrammings: [],
    selectedYears: [],
    activeStatus: new Set<ProgrammingStatus>([ProgrammingStatus.toBeCompleted, ProgrammingStatus.finished])
};

export class FollowUpWorksView extends Component<RouteComponentProps, FollowUpWorksViewState> {
    _isMounted: boolean;
    locationGeometry: Point;
    projectVersionId: number;
    projectId: string;
    mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>;
    projectVersionsCache: Map<number, ProjectVersion>;

    constructor(props) {
        super(props);

        this.mergedProjectAuscultationsCache = new Map<number, MergedProjectVersion>();
        this.projectVersionsCache = new Map<number, ProjectVersion>();

        initialState.currentMeasurementSystemType = MeasurementSystem.getCurrentType();
        this.state = initialState;
    }

    async componentDidMount() {
        this._isMounted = true;

        let locationState = this.props.location.state as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        this.locationGeometry = locationState.locationGeometry;
        this.projectVersionId = locationState.projectVersionId;
        this.projectId = locationState.projectId;

        this.setState({
            loading: true
        });

        await Promise.all([
            RoadsConditionAndScenariosShared.getMergedProject(this.projectVersionId, this.mergedProjectAuscultationsCache, this.projectVersionsCache),
            FollowUpWorksApiClient.GetRoadWorks(this.projectId),
            FollowUpWorksApiClient.GetProjectCurrency(this.projectId)
        ])
            .then((results) => {
                let mergedProject = results[0];
                let programmings = results[1].data;
                let currencySymbole = Currencies[results[2].data];

                this.setState({
                    mergedProject,
                    programmings,
                    currencySymbole,
                    loading: false
                });
            });
    }

    handleDisplaySectionsFromQualityFilters = (activeQualities: Set<number>): void => {
        this.setState({
            activeQualities: activeQualities
        });
    }

    openWorksInformationDrawer = (selectedAreas: Map<number, AreaShapeProps>): void => {
        this.setState({
            isRoadWorksInformationDrawerOpened: true,
            selectedAreas: selectedAreas
        });
    }

    closeWorksInformationDrawer = (): void => {
        this.setState({
            isRoadWorksInformationDrawerOpened: false,
            selectedAreas: new Map<number, AreaShapeProps>()
        });
    }

    handleAreaEnlightenmentChange = (programmingAreaId: number, state: FollowUpWorksViewState): void => {
        let areas = new Map(state.selectedAreas);
        let areaShapeProperties = areas.get(programmingAreaId);
        areaShapeProperties.isEnlightened = !areaShapeProperties.isEnlightened;
        areas.set(programmingAreaId, areaShapeProperties);
        this.setState({
            selectedAreas: areas
        });
    }

    removeAddressIconPin = (): void => {
        this.setState({
            isAddressIconPinOnMap: false,
            addressIconPinPositionOnMap: null
        });
    }

    createAddressIconPin = (position: data.Position): void => {
        this.setState({
            isAddressIconPinOnMap: true,
            addressIconPinPositionOnMap: position
        });
    }

    handleDisplayAreasFromWorksFilter = (selectedProgrammings: string[], selectedYears: number[], activeStatus: Set<ProgrammingStatus>): void => {
        this.setState({
            isRoadWorksInformationDrawerOpened: false,
            selectedAreas: new Map<number, AreaShapeProps>(),
            selectedProgrammings,
            selectedYears,
            activeStatus
        });
    }

    handleMeasurementSystemTypeChanged = (measurementSystemType: MeasurementSystemType): void => {
        this.setState({
            currentMeasurementSystemType: measurementSystemType
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const state = this.state;

        return (
            <Box className="follow-up-works">
                {state.loading ? <PageLoaderComponent /> : null}
                <ActionsMenuComponent
                    activeQualities={state.activeQualities}
                    mergedProject={state.mergedProject}
                    programmings={state.programmings}
                    selectedProgrammings={state.selectedProgrammings}
                    selectedYears={state.selectedYears}
                    activeStatus={state.activeStatus}
                    handleDisplaySections={this.handleDisplaySectionsFromQualityFilters}
                    removeAddressIconPin={this.removeAddressIconPin}
                    createAddressIconPin={this.createAddressIconPin}
                    handleDisplayAreasFromWorksFilter={this.handleDisplayAreasFromWorksFilter}
                />
                <FollowUpWorksMapComponent
                    locationGeometry={this.locationGeometry}
                    mergedProject={state.mergedProject}
                    activeQualities={state.activeQualities}
                    programmings={state.programmings}
                    selectedProgrammings={state.selectedProgrammings}
                    selectedYears={state.selectedYears}
                    activeStatus={state.activeStatus}
                    isAddressIconPinOnMap={state.isAddressIconPinOnMap}
                    addressIconPinPositionOnMap={state.addressIconPinPositionOnMap}
                    selectedAreas={state.selectedAreas}
                    isRoadWorksInformationDrawerOpened={state.isRoadWorksInformationDrawerOpened}
                    openWorksInformationDrawer={this.openWorksInformationDrawer}
                    closeWorksInformationDrawer={this.closeWorksInformationDrawer}
                />
                {state.isRoadWorksInformationDrawerOpened &&
                    <RoadWorksInformationDrawer
                        selectedAreas={state.selectedAreas}
                        currencySymbole={state.currencySymbole}
                        handleClose={this.closeWorksInformationDrawer}
                        handleAreaEnlightenmentChange={(programmingAreaId: number) => this.handleAreaEnlightenmentChange(programmingAreaId, state)}
                    />
                }
            </Box>
        );
    }
}

export default React.forwardRef(withRouter(FollowUpWorksView));
