import { Box, Button } from '@mui/material';
import React from 'react';
import Translate from '../../../../localization/Localization';
import { CostRatio } from '../../../ProjectSettings/services/dataContracts/queryStack/CostRatio';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { Programming } from '../../services/dataContracts/queryStack/Programming';
import { ProgrammingCardComponent } from './ProgrammingCardComponent';
import './ProgrammingsListStyles.scss';

interface ProgrammingsListComponentProps {
    programmingsList: Programming[],
    currency: string,
    mergedProjects: Map<number, MergedProjectVersion>,
    projectId: string,
    costRatiosCache: Map<number, CostRatio[]>,
    currentProjectVersionId: number,
    handleCreateProgrammingClicked: () => void,
    handleOpenProgramming: (programmingId: number) => void,
    handleEditProgramming: (programmingId: number) => void,
    handleDeleteProgramming: (programmingId: number) => void,
    handleDuplicateProgramming: (projectId: string, projectVersionId: number, projectVersionNumber: number, programmingId: number) => void
}

export const ProgrammingsListComponent = (props: ProgrammingsListComponentProps): JSX.Element => {

    return (
        <Box className="programmings-list">
            <Box className="description-module">
                <Box className="description-module-text">
                    <div className="text s1">{Translate.Resources.UI_Programming_Description_ThisModuleEnablesYouRefineAnEstablishedScenario}</div>
                </Box>
                <Button className="btn-primary" onClick={props.handleCreateProgrammingClicked}>
                    {Translate.Resources.UI_Programming_button_CreateProgramme}
                </Button>
            </Box>
            <Box className="programmings">
                <div className="programmings-count">{`${props.programmingsList.length} programmes`}</div>
                <Box className="list">
                    {props.programmingsList.map((programming, index) => {
                        let mergedProject = props.mergedProjects.get(programming.projectVersionId);
                        return (
                            <ProgrammingCardComponent key={`programming-${index}`}
                                programming={programming}
                                currency={props.currency}
                                mergedProject={mergedProject}
                                projectId={props.projectId}
                                costRatiosCache={props.costRatiosCache}
                                currentProjectVersionId={props.currentProjectVersionId}
                                handleOpenProgramming={props.handleOpenProgramming}
                                handleEditProgramming={props.handleEditProgramming}
                                handleDeleteProgramming={props.handleDeleteProgramming}
                                handleDuplicateProgramming={props.handleDuplicateProgramming}
                            />
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
}