import CloseIcon from '@mui/icons-material/Close';
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import Translate from '../../../../localization/Localization';
import { attributeEmpty, attributeEmptyColor, attributesLayers, bikeLaseColors, borderColors, busColors, ditchColors, environmentColors, getColors, hierarchyColors, managerColors, sideColors, trafficColors } from "../../../../shared/Map/AttributesColors";
import { getBooleanList, getEnvironmentList, getHierarchyList, getManagerList, getTrafficList } from "../../../../shared/models/Attributes/AttributesLabels";
import Utilities from '../../../../utils/Utilities';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import DraggableListItem, { Item } from "./DraggableListItem";
import './LayersMenuComponent.scss';

interface LayersMenuComponentProps {
    updateLayerOrder: (order: string[]) => void,
    updateFilter: (filters: { [id: string]: string[] }) => void,
    UpdateLayerMenuDisplay: () => void,
    mergedProjectVersion: MergedProjectVersion
}

interface DragLayer {
    id: string,
    items: Item[],
    label: string,
    visibleByDefault: boolean
}

export const LayersMenuComponent = (props: LayersMenuComponentProps) => {

    const [filters, setFilters] = React.useState<{ [id: string]: string[] }>({});
    const [isAllCollapsed, setIsAllCollapsed] = React.useState<boolean>(null);
    const [dragLayers, setDragLayers] = React.useState<DragLayer[]>();

    const updateFilters = (id: string, values: string[]) => {
        let updatedFilters = Utilities.deepClone(filters);
        updatedFilters[id] = values;
        setFilters(updatedFilters);
    }

    const InitLayers = (mergedProjectVersion: MergedProjectVersion) => {

        let dragLayers: DragLayer[] = [];

        let municipalityColors = getColors(attributesLayers["municipality"], Array.from(mergedProjectVersion.municipalities).sort((a, b) => String(a[0]).localeCompare(b[0])), true);
        dragLayers.push({
            id: attributesLayers["municipality"],
            items: Object.keys(municipalityColors).map(e => ({ key: e, color: municipalityColors[e], label: e })),
            label: Translate.Resources.UI_Highways_Grid_City,
            visibleByDefault: true
        });

        let districtColors = getColors(attributesLayers["district"], Array.from(mergedProjectVersion.districts).sort((a, b) => String(a[0]).localeCompare(b[0])), true);
        dragLayers.push({
            id: attributesLayers["district"],
            items: Object.keys(districtColors).map(e => ({ key: e, color: districtColors[e], label: e })),
            label: Translate.Resources.UI_Highways_Grid_District,
            visibleByDefault: false
        });

        let collaborativeDevelopmentZoneColors = getColors(attributesLayers["collaborativeDevelopmentZone"], Array.from(mergedProjectVersion.collaborativeDevelopmentZones).sort((a, b) => String(a[0]).localeCompare(b[0])), true);
        dragLayers.push({
            id: attributesLayers["collaborativeDevelopmentZone"],
            items: Object.keys(collaborativeDevelopmentZoneColors).map(e => ({ key: e, color: collaborativeDevelopmentZoneColors[e], label: e })),
            label: Translate.Resources.UI_Highways_Grid_CollaborativeDevelopmentZone,
            visibleByDefault: false
        });

        dragLayers.push({
            id: attributesLayers["hierarchy"],
            items: Object.keys(hierarchyColors).map(e => ({ key: e, color: hierarchyColors[e], label: getHierarchyList().find((c) => c.value === e)?.text })),
            label: Translate.Resources.UI_Highways_Grid_Hierarchy,
            visibleByDefault: false
        });

        dragLayers.push({
            id: attributesLayers["traffic"],
            items: Object.keys(trafficColors).map(e => ({ key: e, color: trafficColors[e], label: getTrafficList().find((c) => c.value === e)?.text })),
            label: Translate.Resources.UI_Highways_Grid_Traffic,
            visibleByDefault: false
        });

        dragLayers.push({
            id: attributesLayers["environment"],
            items: Object.keys(environmentColors).map(e => ({ key: e, color: environmentColors[e], label: getEnvironmentList().find((c) => c.value === e)?.text })),
            label: Translate.Resources.UI_Highways_Grid_Environment,
            visibleByDefault: false
        });

        dragLayers.push({
            id: attributesLayers["manager"],
            items: Object.keys(managerColors).map(e => ({ key: e, color: managerColors[e], label: getManagerList().find((c) => c.value === e)?.text })),
            label: Translate.Resources.UI_Highways_Grid_Manager,
            visibleByDefault: false
        });

        dragLayers.push({
            id: attributesLayers["bus"],
            items: Object.keys(busColors).map(e => ({ key: e, color: busColors[e], label: getBooleanList().find((c) => c.value === (e === "true"))?.text })),
            label: Translate.Resources.UI_Highways_Grid_Bus,
            visibleByDefault: false
        });

        dragLayers.push({
            id: attributesLayers["bikeLase"],
            items: Object.keys(bikeLaseColors).map(e => ({ key: e, color: bikeLaseColors[e], label: getBooleanList().find((c) => c.value === (e === "true"))?.text })),
            label: Translate.Resources.UI_Highways_Grid_BikeLase,
            visibleByDefault: false
        });

        dragLayers.push({
            id: attributesLayers["border"],
            items: Object.keys(borderColors).map(e => ({ key: e, color: borderColors[e], label: getBooleanList().find((c) => c.value === (e === "true"))?.text })),
            label: Translate.Resources.UI_Highways_Grid_Border,
            visibleByDefault: false
        });

        dragLayers.push({
            id: attributesLayers["ditch"],
            items: Object.keys(ditchColors).map(e => ({ key: e, color: ditchColors[e], label: getBooleanList().find((c) => c.value === (e === "true"))?.text })),
            label: Translate.Resources.UI_Highways_Grid_Ditch,
            visibleByDefault: false
        });

        dragLayers.push({
            id: attributesLayers["side"],
            items: Object.keys(sideColors).map(e => ({ key: e, color: sideColors[e], label: getBooleanList().find((c) => c.value === (e === "true"))?.text })),
            label: Translate.Resources.UI_Highways_Grid_Side,
            visibleByDefault: false
        });

        dragLayers.forEach(e => {
            e.items.push({ key: attributeEmpty, color: attributeEmptyColor, label: Translate.Resources.UI_ActionsMenu_Empty })
        })


        setDragLayers(dragLayers);
        props.updateLayerOrder(dragLayers.map(e => e.id));
    }

    const UpdateLayers = (mergedProjectVersion: MergedProjectVersion) => {

        if (!dragLayers) {
            InitLayers(mergedProjectVersion);
            return;
        }

        let updatedDragLayers: DragLayer[] = dragLayers;

        let municipalityColors = getColors(attributesLayers["municipality"], Array.from(mergedProjectVersion.municipalities).sort((a, b) => String(a[0]).localeCompare(b[0])), true);
        updatedDragLayers.filter(x => x.id === attributesLayers["municipality"])[0].items = Object.keys(municipalityColors).map(e => ({ key: e, color: municipalityColors[e], label: e }));


        let districtColors = getColors(attributesLayers["district"], Array.from(mergedProjectVersion.districts).sort((a, b) => String(a[0]).localeCompare(b[0])), true);
        updatedDragLayers.filter(x => x.id === attributesLayers["district"])[0].items = Object.keys(districtColors).map(e => ({ key: e, color: districtColors[e], label: e }));

        let collaborativeDevelopmentZoneColors = getColors(attributesLayers["collaborativeDevelopmentZone"], Array.from(mergedProjectVersion.collaborativeDevelopmentZones).sort((a, b) => String(a[0]).localeCompare(b[0])), true);
        updatedDragLayers.filter(x => x.id === attributesLayers["collaborativeDevelopmentZone"])[0].items = Object.keys(collaborativeDevelopmentZoneColors).map(e => ({ key: e, color: collaborativeDevelopmentZoneColors[e], label: e }));

        updatedDragLayers.filter(x => x.id === attributesLayers["hierarchy"])[0].items = Object.keys(hierarchyColors).map(e => ({ key: e, color: hierarchyColors[e], label: getHierarchyList().find((c) => c.value === e)?.text }));

        updatedDragLayers.filter(x => x.id === attributesLayers["traffic"])[0].items = Object.keys(trafficColors).map(e => ({ key: e, color: trafficColors[e], label: getTrafficList().find((c) => c.value === e)?.text }));

        updatedDragLayers.filter(x => x.id === attributesLayers["environment"])[0].items = Object.keys(environmentColors).map(e => ({ key: e, color: environmentColors[e], label: getEnvironmentList().find((c) => c.value === e)?.text }));

        updatedDragLayers.filter(x => x.id === attributesLayers["manager"])[0].items = Object.keys(managerColors).map(e => ({ key: e, color: managerColors[e], label: getManagerList().find((c) => c.value === e)?.text }));

        updatedDragLayers.filter(x => x.id === attributesLayers["bus"])[0].items = Object.keys(busColors).map(e => ({ key: e, color: busColors[e], label: getBooleanList().find((c) => c.value === (e === "true"))?.text }));

        updatedDragLayers.filter(x => x.id === attributesLayers["bikeLase"])[0].items = Object.keys(bikeLaseColors).map(e => ({ key: e, color: bikeLaseColors[e], label: getBooleanList().find((c) => c.value === (e === "true"))?.text }));

        updatedDragLayers.filter(x => x.id === attributesLayers["border"])[0].items = Object.keys(borderColors).map(e => ({ key: e, color: borderColors[e], label: getBooleanList().find((c) => c.value === (e === "true"))?.text }));

        updatedDragLayers.filter(x => x.id === attributesLayers["ditch"])[0].items = Object.keys(ditchColors).map(e => ({ key: e, color: ditchColors[e], label: getBooleanList().find((c) => c.value === (e === "true"))?.text }));

        updatedDragLayers.filter(x => x.id === attributesLayers["side"])[0].items = Object.keys(sideColors).map(e => ({ key: e, color: sideColors[e], label: getBooleanList().find((c) => c.value === (e === "true"))?.text }));

        dragLayers.forEach(e => {
            e.items.push({ key: attributeEmpty, color: attributeEmptyColor, label: Translate.Resources.UI_ActionsMenu_Empty })
        })

        setDragLayers(updatedDragLayers);
        props.updateLayerOrder(updatedDragLayers.map(e => e.id));
    }

    useEffect(() => {
        if (!props.mergedProjectVersion)
            return;
        UpdateLayers(props.mergedProjectVersion);

    }, [props.mergedProjectVersion]);

    useEffect(() => {
        props.updateFilter(filters);
    }, [JSON.stringify(filters)]);

    const reorder = (
        list: DragLayer[],
        startIndex: number,
        endIndex: number
    ) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = ({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return;

        const newItems = reorder(dragLayers, source.index, destination.index);
        setDragLayers(newItems);

        //Update Order On map
        props.updateLayerOrder(newItems.map(e => e.id));
    };

    const uncollapsed = () => {
        setIsAllCollapsed(false)
    }

    const collapsAll = () => {
        setIsAllCollapsed(true)
    }
    const getDraggableListItems = (dragLayer: DragLayer, index: number): JSX.Element =>
        <DraggableListItem
            key={dragLayer.id}
            onClick={(e) => updateFilters(dragLayer.id, e)}
            items={dragLayer.items}
            label={dragLayer.label}
            index={index}
            visibleByDefault={dragLayer.visibleByDefault}
            isCollapsed={isAllCollapsed}
            uncollapsed={uncollapsed}
        ></DraggableListItem>;

    return (
        <Box className="highways-layer-menu" display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
            <Box className="highways-layer-menu-header" display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                <label className="display-all" onClick={collapsAll}>{Translate.Resources.UI_Highways_Layers_Menu_Collapse_All} </label>
                <CloseIcon className="close-panel" onClick={props.UpdateLayerMenuDisplay}></CloseIcon>
            </Box>
            <div className="list">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-list">
                        {provided => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {dragLayers?.map((item, index) => getDraggableListItems(item, index))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </Box >
    );
}