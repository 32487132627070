import axios, { AxiosResponse } from "axios";
import { CommandResult } from "../../../shared/models/CommandResult";
import { CostRatio } from "../../ProjectSettings/services/dataContracts/queryStack/CostRatio";
import { WorkPriority } from "../../ProjectSettings/services/dataContracts/queryStack/WorkPriority";
import { HigherProjectVersion } from "../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/HigherProjectVersion";
import { AddScenarioRequestArgs } from "./dataContracts/controller/AddScenarioRequestArgs";
import { DeleteScenarioRequestArgs } from "./dataContracts/controller/DeleteScenarioRequestArgs";
import { DuplicateScenarioRequestArgs } from "./dataContracts/controller/DuplicateScenarioRequestArgs";
import { UpdateScenarioRequestArgs } from "./dataContracts/controller/UpdateScenarioRequestArgs";
import { UpdateScenarioSectionsRequestArgs } from "./dataContracts/controller/UpdateScenarioSectionsRequestArgs";
import { Scenario } from "./dataContracts/queryStack/Scenario";

const controllerUrl = 'api/Scenarios/';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

export class ScenariosApiClient {

    public static AddScenario = (requestArgs: AddScenarioRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}AddScenario`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static UpdateScenario = (requestArgs: UpdateScenarioRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateScenario`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static DeleteScenario = (requestArgs: DeleteScenarioRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}DeleteScenario`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static DuplicateScenario = (requestArgs: DuplicateScenarioRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}DuplicateScenario`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static GetProjectCurrency = (projectId: string)
        : Promise<AxiosResponse<string>> => {
        return axios({
            method: 'GET',
            url: `api/ProjectSettings/GetProjectCurrency?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }

    public static GetScenarios = (projectId: string)
        : Promise<AxiosResponse<Scenario[]>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetScenarios?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }

    public static GetScenario = (scenarioId: number)
        : Promise<AxiosResponse<Scenario>> => {
        return axios({
            method: 'GET',
            url: `${controllerUrl}GetScenario?scenarioId=${encodeURIComponent(scenarioId)}`,
            headers: headers
        });
    }

    public static UpdateScenarioSections = (requestArgs: UpdateScenarioSectionsRequestArgs)
        : Promise<AxiosResponse<CommandResult>> => {
        return axios({
            method: 'POST',
            url: `${controllerUrl}UpdateScenarioSections`,
            headers: headers,
            data: JSON.stringify(requestArgs)
        });
    }

    public static GetWorkPriorities = (projectId: string)
        : Promise<AxiosResponse<Array<WorkPriority>>> => {
        return axios({
            method: 'GET',
            url: `api/ProjectSettings/GetWorkPriorities?projectId=${encodeURIComponent(projectId)}`,
            headers: headers
        });
    }

    public static GetCostRatios = (projectId: string, year: number)
        : Promise<AxiosResponse<Array<CostRatio>>> => {
        return axios({
            method: 'GET',
            url: `api/ProjectSettings/GetCostRatios?projectId=${encodeURIComponent(projectId)}&year=${encodeURIComponent(year)}`,
            headers: headers
        });
    }

    public static GetProjectHigherVersions = (projectId: string, currentProjectVersionId: number)
        : Promise<AxiosResponse<Array<HigherProjectVersion>>> => {
        return axios({
            method: 'GET',
            url: `api/RoadsCondition/GetProjectHigherVersions?projectId=${encodeURIComponent(projectId)}&currentProjectVersionId=${encodeURIComponent(currentProjectVersionId)}`,
            headers: headers
        });
    }
}