import { Box } from '@mui/system';
import { AnimationOptions, CameraBoundsOptions, data, layer, Map as AzureMap, MapMouseEvent, math, Shape, source } from 'azure-maps-control';
import { isEqual, orderBy } from 'lodash';
import React, { useEffect, useRef } from 'react';
import pinIcon from 'src/assets/icons/icon-pin.svg';
import securityHelmetIcon from 'src/assets/icons/icon-security-helmet.png';
import selectedWorkDoneAndToDoMultiAreasConeIcon from 'src/assets/icons/icon-selected-work-done-and-to-do-multi-areas-cone.svg';
import selectedWorkDoneAreaConeIcon from 'src/assets/icons/icon-selected-work-done-area-cone.svg';
import selectedWorkDoneMultiAreasConeIcon from 'src/assets/icons/icon-selected-work-done-multi-areas-cone.svg';
import selectedWorkToDoAreaConeIcon from 'src/assets/icons/icon-selected-work-to-do-area-cone.svg';
import selectedWorkToDoMultiAreasConeIcon from 'src/assets/icons/icon-selected-work-to-do-multi-areas-cone.svg';
import workDoneAndToDoMultiAreasConeIcon from 'src/assets/icons/icon-work-done-and-to-do-multi-areas-cone.svg';
import workDoneAreaConeIcon from 'src/assets/icons/icon-work-done-area-cone.svg';
import workDoneMultiAreasConeIcon from 'src/assets/icons/icon-work-done-multi-areas-cone.svg';
import workToDoAreaConeIcon from 'src/assets/icons/icon-work-to-do-area-cone.svg';
import workToDoMultiAreasConeIcon from 'src/assets/icons/icon-work-to-do-multi-areas-cone.svg';
import { ProgrammingStatus } from '../../../shared/components/ActionsMenu/models/ProgrammingStatus';
import { MapActionBar } from '../../../shared/components/MapActionBar/MapActionBar';
import { areaWidth, cameraAnimationDuration, cameraAnimationType, createMap, createShape, getAreaShapeId, getEnlightenedAreaShapeId, getMapChoiceValue, getSectionShapeId, hideShape, sectionWidth, setMapCursor, transparentColor } from '../../../shared/Map/MapUtils';
import { MapCursorMode } from '../../../shared/models/MapCursorMode';
import { Point } from '../../../shared/models/Point';
import { ShapeEntityType } from '../../../shared/models/ShapeEntityType';
import MathExtensions from '../../../utils/MathExtensions';
import styles from '../../../_variables.scss';
import styles2 from '../../../_variables2.scss';
import { AreaSection } from '../../Programmings/services/dataContracts/queryStack/AreaSection';
import { Programming } from '../../Programmings/services/dataContracts/queryStack/Programming';
import { ProgrammingArea } from '../../Programmings/services/dataContracts/queryStack/ProgrammingArea';
import { ProgrammingAreaStatus } from '../../Programmings/services/dataContracts/queryStack/ProgrammingAreaStatus';
import { MergedProjectVersion } from '../../RoadsCondition/models/MergedProjectVersion';
import { RoadSectionViewData } from '../../RoadsCondition/models/RoadSectionViewData';
import { ScoreTypesColors } from '../../RoadsCondition/models/ScoreTypesColors';
import { mainDatasourceId, roadLayerId, RoadsConditionAndScenariosShared } from '../../RoadsCondition/RoadsConditionAndScenariosShared';
import { AreaShapeProps } from '../models/AreaShapeProps';
import { ProgrammingAreaExtended } from '../models/ProgrammingAreaExtended';
import './FollowUpWorksMapStyles.scss';

let shapeAreasDico = new Map<number, ProgrammingAreaExtended>();
let conePinIds = new Set<string>();
let areaEnlightenedShapeIds = new Map<string, number>();
let masquedConeShape = null;
let selectedConeShape = null;
let enlightenedAreasIds = new Set<number>()

interface FollowUpWorksMapComponentProps {
    locationGeometry: Point,
    mergedProject: MergedProjectVersion,
    activeQualities: Set<number>,
    programmings: Programming[],
    selectedProgrammings: string[],
    selectedYears: number[],
    activeStatus: Set<ProgrammingStatus>,
    isAddressIconPinOnMap: boolean,
    addressIconPinPositionOnMap: data.Position,
    selectedAreas: Map<number, AreaShapeProps>,
    isRoadWorksInformationDrawerOpened: boolean,
    openWorksInformationDrawer: (selectedAreas: Map<number, AreaShapeProps>) => void,
    closeWorksInformationDrawer: () => void
}

export const FollowUpWorksMapComponent = (props: FollowUpWorksMapComponentProps): JSX.Element => {

    const coneDatasourceId = 'coneDatasourceId';

    const helmetDatasourceId = "helmetDatasourceId";
    const helmetIconSymbolLayerId = "helmetIconSymbolLayerId";
    const helmetId = "helmetId";
    const helmetEntityType = "helmetEntityType";

    const coneIconSymbolLayerId = "coneIconSymbolLayerId";
    const selectedConeIconSymbolLayerId = "selectedConeIconSymbolLayerId";
    const areaConeEntityTypes = {
        workToDoArea: "workToDoArea",
        workDoneArea: "workDoneArea",
        workToDoMultiArea: "workToDoMultiArea",
        workDoneMultiArea: "workDoneMultiArea",
        workToDoAndWorkDoneMultiArea: "workToDoAndWorkDoneMultiArea"
    };

    const addressDatasourceId = "addressDatasourceId";
    const addressIconSymbolLayerId = "addressIconSymbolLayerId";
    const addressId = "addressId";
    const addressEntityType = "addressEntityType";

    const azureMap = useRef<AzureMap>();

    useEffect(() => {
        if (!azureMap.current) {
            let mapChoice = getMapChoiceValue();
            azureMap.current = createMap('AzureMap', 4, props.locationGeometry, mapChoice);
        }

        if (props.mergedProject && props.programmings) {
            initMap(azureMap.current, () => {
                let mainDatasource = createMainDatasource();
                let coneDatasource = createConeDatasource();

                let areas = createExtendedAreas(props.programmings, props.mergedProject);

                createMapSectionsShapes(props.mergedProject, mainDatasource, areas);
                createMapAreasShapes(areas, mainDatasource, coneDatasource);

                createAddressDatasource();
                createHelmetDatasource();

                setMapZoom(azureMap.current, props.mergedProject);
            });
        }
    }, [props.mergedProject, props.programmings]);

    useEffect(() => {
        if (props.mergedProject) {
            let roadsSections = props.mergedProject.roadsSections;
            let filteredSections = updateSectionsVisibility(roadsSections, props.activeQualities);

            let datasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
            if (datasource) {
                datasource.getShapes().forEach((section: Shape) => {
                    let properties = section.getProperties();
                    let entityType = properties.EntityType;
                    if (entityType === ShapeEntityType.section) {
                        let sectionId = properties.RoadSectionId;
                        let sectionView = props.mergedProject.roadsSections.get(sectionId);

                        let strokeColor = styles.unfilteredSectionColor;
                        let sectionScoreType = sectionView.scoreType;

                        if (filteredSections.has(sectionId)) {
                            strokeColor = sectionScoreType ? ScoreTypesColors.get(sectionScoreType) : styles2.emptyQualityColor;
                        }

                        if (strokeColor !== properties.strokeColor) {
                            properties.strokeColor = strokeColor;
                            section.setProperties(properties);
                        }
                    }
                });
            }
        }
    }, [props.activeQualities])

    useEffect(() => {
        if (azureMap.current) {
            let datasource = azureMap.current.sources.getById(addressDatasourceId) as source.DataSource;

            if (datasource && props.isAddressIconPinOnMap && props.addressIconPinPositionOnMap) {
                createAddressIconPin(props.addressIconPinPositionOnMap, datasource);

                azureMap.current.setCamera({
                    zoom: 16,
                    center: props.addressIconPinPositionOnMap,
                    type: cameraAnimationType,
                    duration: cameraAnimationDuration
                });
            }

            if (datasource && !props.isAddressIconPinOnMap) {
                removeAddressIconPin(datasource);
            }
        }
    }, [props.isAddressIconPinOnMap, props.addressIconPinPositionOnMap])

    useEffect(() => {
        if (azureMap.current) {
            let areasDico: Map<number, ProgrammingAreaExtended> = new Map<number, ProgrammingAreaExtended>();

            let mainDatasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
            if (mainDatasource) {
                let programmings = new Set(props.selectedProgrammings);
                let years = new Set(props.selectedYears);

                mainDatasource.getShapes().forEach((shape: Shape) => {
                    let properties = shape.getProperties();
                    let entityType = properties.EntityType;

                    if (entityType === ShapeEntityType.area) {
                        let areaProps: AreaShapeProps = properties.areaProps;
                        let areaStatus = areaProps.status;

                        let condition = true;
                        if (condition && years.size > 0) {
                            condition = condition && years.has(areaProps.year);
                        }

                        if (condition && programmings.size > 0) {
                            condition = condition && programmings.has(areaProps.programmingOptionLabel);
                        }

                        if (condition && props.activeStatus.has(ProgrammingStatus.toBeCompleted) && !props.activeStatus.has(ProgrammingStatus.finished)) {
                            condition = condition && areaStatus === ProgrammingAreaStatus.workToDo;
                        }

                        if (condition && props.activeStatus.has(ProgrammingStatus.finished) && !props.activeStatus.has(ProgrammingStatus.toBeCompleted)) {
                            condition = condition && areaStatus === ProgrammingAreaStatus.workDone;
                        }

                        if (condition && props.activeStatus.has(ProgrammingStatus.finished) && props.activeStatus.has(ProgrammingStatus.toBeCompleted)) {
                            condition = condition && (areaStatus === ProgrammingAreaStatus.workDone || areaStatus === ProgrammingAreaStatus.workToDo);
                        }

                        if (condition && !props.activeStatus.has(ProgrammingStatus.finished) && !props.activeStatus.has(ProgrammingStatus.toBeCompleted)) {
                            condition = condition && areaStatus === null;
                        }

                        if (condition) {
                            areaProps.isFiltered = true;
                            properties.areaProps = areaProps;

                            if (!isShapeVisible(properties)) {
                                showShape(shape, areaProps.hexColor, properties);
                            }

                            let area = shapeAreasDico.get(areaProps.programmingAreaId);
                            if (!areasDico.has(area.programmingAreaId)) {
                                areasDico.set(area.programmingAreaId, area);
                            }
                        }
                        else {
                            areaProps.isFiltered = false;
                            properties.areaProps = areaProps;

                            if (isShapeVisible(properties)) {
                                hideShape(shape);
                            }
                        }
                    }

                    shape.setProperties(properties);
                });
                handleZoomend();
            }

            let coneDatasource = azureMap.current.sources.getById(coneDatasourceId) as source.DataSource;
            if (coneDatasource) {
                removeHelmetIconPinAndHideAreaEnlightenedShapes();
                removeConePins(coneDatasource);

                let areaGroupings = createAreasGroupings(areasDico);
                createConePins(areaGroupings, coneDatasource);
            }
        }
    }, [props.selectedProgrammings, props.selectedYears, props.activeStatus])

    useEffect(() => {
        if (azureMap.current && props.mergedProject) {
            let mainDatasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;

            props.selectedAreas.forEach((area) => {
                let selectedArea = shapeAreasDico.get(area.programmingAreaId);
                selectedArea.sections.map(x => x.roadSectionId).forEach((sectionId: number) => {
                    let shapeId = getEnlightenedAreaShapeId(sectionId);
                    if (area.isEnlightened) {
                        RoadsConditionAndScenariosShared.showAreaEnlightenedShape(shapeId, mainDatasource);
                        areaEnlightenedShapeIds.set(shapeId, sectionId);
                        enlightenedAreasIds.add(area.programmingAreaId);

                        let areaShape = mainDatasource.getShapeById(getAreaShapeId(sectionId));
                        hideShape(areaShape);
                    }
                    else {
                        RoadsConditionAndScenariosShared.hideAreaEnlightenedShape(shapeId, mainDatasource);
                        areaEnlightenedShapeIds.delete(shapeId);
                        enlightenedAreasIds.delete(area.programmingAreaId);

                        if (azureMap.current.getCamera().zoom >= 16) {
                            let areaShape = mainDatasource.getShapeById(getAreaShapeId(sectionId));
                            showAreaShape(areaShape);
                        }
                    }
                });
            });
        }
    }, [props.selectedAreas])

    useEffect(() => {
        azureMap.current.resize();
    }, [props.isRoadWorksInformationDrawerOpened])

    useEffect(() => {
        return function cleanup() {
            azureMap.current?.dispose();
        }
    }, [])

    const initMap = (map: AzureMap, callback: () => void): void => {
        setMapCursor(map, MapCursorMode.Auto);

        map.events.add('load', () => {

            map.events.add('mousedown', handleMapMousedown);
            map.events.add('zoomend', () => handleZoomend());

            if (callback) {
                callback();
            }
        });
    }

    const handleZoomend = (): void => {
        let zoom = azureMap.current.getCamera().zoom;

        if (zoom >= 16) {
            displayAreaShapes(true);
            displayConeIconSymbolLayer(azureMap.current, false);
        }
        else {
            displayAreaShapes(false);
            displayConeIconSymbolLayer(azureMap.current, true);
        }
    }

    const displayAreaShapes = (isVisible: boolean) => {
        let mainDatasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
        mainDatasource.getShapes().forEach((shape: Shape) => {
            let properties = shape.getProperties();
            let entityType = properties.EntityType;

            if (entityType === ShapeEntityType.area) {
                if (properties.areaProps.isFiltered) {
                    if (!isShapeVisible(properties) && isVisible && !enlightenedAreasIds.has(properties.areaProps.programmingAreaId)) {
                        showAreaShape(shape);
                    }
                    else if (isShapeVisible(properties) && !isVisible) {
                        hideShape(shape);
                    }
                }
                else {
                    hideShape(shape);
                }
            }
        });
    }

    const displayConeIconSymbolLayer = (map: AzureMap, isVisible: boolean): void => {
        let conIconLayer = map.layers.getLayerById(coneIconSymbolLayerId) as layer.SymbolLayer;
        if (conIconLayer) {
            let options = conIconLayer.getOptions();

            if (options.visible !== isVisible) {
                options.visible = isVisible;
                conIconLayer.setOptions(options);
            }
        }
    }

    const handleMapMousedown = (e: MapMouseEvent): void => {
        let mainDatasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
        removeHelmetIconPinAndHideAreaEnlightenedShapes();
        removeSelectedCone();

        enlightenedAreasIds = new Set<number>();

        let shapes = e.shapes.filter(x => 'getProperties' in x);
        let selectedAreas: Map<number, AreaShapeProps> = new Map<number, AreaShapeProps>();

        if (hasConeShape(shapes)) {
            let shapeIndex = 0;
            let clickedShape: Shape = null;
            let clickedShapeProps = null;

            for (let i = 0; i < shapes.length; i++) {
                let shape = shapes[i] as Shape;
                let props = shape.getProperties();
                if (props.isConeIcon) {
                    if (shapeIndex <= props.index) {
                        shapeIndex = props.index;
                        clickedShape = shape;
                        clickedShapeProps = props;
                    }
                }
            }

            clickedShapeProps.areas.forEach((area: AreaShapeProps) => {
                let programmingAreaId = area.programmingAreaId;
                if (!selectedAreas.has(programmingAreaId)) {
                    area.isEnlightened = true;
                    selectedAreas.set(programmingAreaId, area);
                    enlightenedAreasIds.add(programmingAreaId);
                }
            });

            let coneDatasource = azureMap.current.sources.getById(coneDatasourceId) as source.DataSource;

            if (clickedShape && clickedShapeProps) {
                clickedShapeProps.visible = false;
                masquedConeShape = clickedShape;
            }

            let selectedShapeId = getSelectedConeShapeId(shapeIndex);
            let selectedShape = coneDatasource.getShapeById(selectedShapeId);
            if (selectedShape) {
                let selectedShapeProps = selectedShape.getProperties();
                selectedShapeProps.visible = true;
                selectedShape.setProperties(selectedShapeProps);
                selectedConeShape = selectedShape;
            }
        }
        else if (hasAreaShapes(shapes)) {
            let areaShapeIndex = 0;
            for (let i = 0; i < shapes.length; i++) {
                let shape = shapes[i] as Shape;
                let props = shape.getProperties();
                let areaProps = props.areaProps as AreaShapeProps;
                if (props.EntityType === ShapeEntityType.area && areaProps.isFiltered === true) {
                    let coordinates = shape.getCoordinates() as data.Position[];
                    if (areaShapeIndex === 0) {
                        let midPoint = MathExtensions.interpolateMidPoint(coordinates);
                        createHelmetIconPin(midPoint);
                    }

                    let programmingAreaId = areaProps.programmingAreaId;
                    if (!selectedAreas.has(programmingAreaId)) {
                        areaProps.isEnlightened = true;
                        enlightenedAreasIds.add(programmingAreaId);
                        selectedAreas.set(programmingAreaId, areaProps);
                        shape.setProperties(props);
                    }

                    areaShapeIndex += 1;
                }
            }
        }

        if (selectedAreas.size > 0) {
            selectedAreas.forEach((shapeProperties: AreaShapeProps) => {
                let area = shapeAreasDico.get(shapeProperties.programmingAreaId);
                area.sections.map(x => x.roadSectionId).forEach((sectionId: number) => {
                    let shapeId = getEnlightenedAreaShapeId(sectionId);
                    RoadsConditionAndScenariosShared.showAreaEnlightenedShape(shapeId, mainDatasource);

                    let areaShape = mainDatasource.getShapeById(getAreaShapeId(sectionId));
                    hideShape(areaShape);

                    let enlightenedShapeIds = areaEnlightenedShapeIds;
                    enlightenedShapeIds.set(shapeId, sectionId);
                    areaEnlightenedShapeIds = enlightenedShapeIds;
                });
            });

            props.openWorksInformationDrawer(selectedAreas);
            return;
        }

        props.closeWorksInformationDrawer();
        handleZoomend();
    }

    const createHelmetIconPin = (coordinates: data.Position): void => {
        let datasource = azureMap.current.sources.getById(helmetDatasourceId) as source.DataSource;
        let pin = new data.Feature(new data.Point(coordinates), { EntityType: helmetEntityType }, helmetId);
        datasource.add(pin);
    }

    const removeHelmetIconPinAndHideAreaEnlightenedShapes = (): void => {
        removeHelmetIconPin();
        handleHideAreaEnlightenedShapes();
        areaEnlightenedShapeIds = new Map<string, number>();
    }

    const removeHelmetIconPin = (): void => {
        let datasource = azureMap.current.sources.getById(helmetDatasourceId) as source.DataSource;
        let shape = datasource.getShapeById(helmetId);
        if (shape) {
            datasource.remove(shape);
        }
    }

    const handleHideAreaEnlightenedShapes = (): void => {
        let mainDatasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
        areaEnlightenedShapeIds.forEach((sectionId, shapeId) => {
            RoadsConditionAndScenariosShared.hideAreaEnlightenedShape(shapeId, mainDatasource);

            if (azureMap.current.getCamera().zoom >= 16) {
                let areaShape = mainDatasource.getShapeById(getAreaShapeId(sectionId));
                showAreaShape(areaShape);
            }
        });
    }

    const removeSelectedCone = (): void => {
        if (masquedConeShape) {
            let coneShape = masquedConeShape;
            let masquedProps = coneShape.getProperties();
            masquedProps.visible = true;
            coneShape.setProperties(masquedProps);
        }

        if (selectedConeShape) {
            let selectedoneShape = selectedConeShape;
            let selectedShapeProps = selectedoneShape.getProperties();
            selectedShapeProps.visible = false;
            selectedoneShape.setProperties(selectedShapeProps);
        }
    }

    const hasAreaShapes = (shapes: Array<data.Feature<data.Geometry, any> | Shape>): boolean => {
        let isAreaEntityShape = false;
        for (let s of shapes) {
            let shape = s as Shape;
            let props = shape.getProperties();
            if (props && (props.EntityType === ShapeEntityType.area || props.EntityType === ShapeEntityType.areaEnlightened)) {
                isAreaEntityShape = true;
                break;
            }
        }

        return isAreaEntityShape;
    }

    const hasConeShape = (shapes: Array<data.Feature<data.Geometry, any> | Shape>): boolean => {
        let isAreaConeEntityShape = false;
        for (let s of shapes) {
            let shape = s as Shape;
            let props = shape.getProperties();
            if (props && props.isConeIcon) {
                isAreaConeEntityShape = true;
                break;
            }
        }

        return isAreaConeEntityShape;
    }

    const createMapSectionsShapes = (mergedProject: MergedProjectVersion, mainDatasource: source.DataSource, areas: Map<number, ProgrammingAreaExtended>): void => {
        const mapEntries = [...areas].map(([key, value]) => (value));
        orderBy(mapEntries, [(a) => { return a.programmingAreaId }], ['asc'])
            .forEach((entry: ProgrammingAreaExtended) => {
                entry.sections.forEach((section: AreaSection) => {
                    let enlightenedAreaShapeId = getEnlightenedAreaShapeId(section.roadSectionId);
                    let selectedAreaShape = createShape(section.pathGeometry.coordinates, enlightenedAreaShapeId, transparentColor, 0, ShapeEntityType.areaEnlightened, section.roadSectionId);
                    mainDatasource.add(selectedAreaShape);
                });
            });

        mergedProject.roadsSections.forEach((section) => {
            let coordinates = section.pathGeometry.coordinates;
            let roadSectionId = section.roadSectionId;

            let shapeId = getSectionShapeId(roadSectionId);
            let sectionScoreType = section.scoreType;
            let strokeColor = styles.unfilteredSectionColor;
            let sectionShape = createShape(coordinates, shapeId, strokeColor, sectionWidth, ShapeEntityType.section, roadSectionId, sectionScoreType);
            mainDatasource.add(sectionShape);
        });
    }

    const createMainDatasource = (): source.DataSource => {
        let datasource = new source.DataSource(mainDatasourceId);
        azureMap.current.sources.add(datasource);

        let roadLayer = RoadsConditionAndScenariosShared.createLineLayer(datasource, roadLayerId);
        azureMap.current.layers.add(roadLayer);

        azureMap.current.events.add('mouseover', roadLayer, (e) => {
            let isAreaEntityType = e.shapes.some(x => (x as Shape).getProperties().EntityType === ShapeEntityType.area)
            if (isAreaEntityType) {
                handleLayerMouseover();
            }
        });
        azureMap.current.events.add('mouseout', roadLayer, handleLayerMouseout);

        return datasource;
    }

    const createConeDatasource = (): source.DataSource => {
        let coneDatasource = new source.DataSource(coneDatasourceId);
        azureMap.current.sources.add(coneDatasource);

        let coneSymbolLayer = createConeIconSymbolLayer(coneDatasource, coneIconSymbolLayerId);
        let selectedConeSymbolLayer = createSelectedConeIconSymbolLayer(coneDatasource, selectedConeIconSymbolLayerId);

        azureMap.current.layers.add([coneSymbolLayer, selectedConeSymbolLayer]);

        azureMap.current.events.add('mouseover', coneSymbolLayer, handleLayerMouseover);
        azureMap.current.events.add('mouseout', coneSymbolLayer, handleLayerMouseout);

        return coneDatasource;
    }

    const createConeIconSymbolLayer = (datasource: source.DataSource, id: string): layer.SymbolLayer => {
        let symbolLayer: layer.SymbolLayer = new layer.SymbolLayer(datasource, id, {
            iconOptions: {
                image: ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workDoneArea], 'workDoneAreaConeIcon',
                    ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workDoneMultiArea], 'workDoneMultiAreasConeIcon',
                        ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workToDoArea], 'workToDoAreaConeIcon',
                            ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workToDoMultiArea], 'workToDoMultiAreasConeIcon',
                                ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workToDoAndWorkDoneMultiArea], 'workDoneAndToDoMultiAreasConeIcon', '']
                            ]
                        ]
                    ]
                ],
                anchor: 'center',
                ignorePlacement: true,
                allowOverlap: true,
                rotationAlignment: 'map'
            },
            filter: ['all', ['has', 'isConeIcon'], ['==', ['get', 'visible'], true]],
            zOrder: 'source'
        });

        azureMap.current.imageSprite.add('workDoneAreaConeIcon', workDoneAreaConeIcon);
        azureMap.current.imageSprite.add('workDoneMultiAreasConeIcon', workDoneMultiAreasConeIcon);
        azureMap.current.imageSprite.add('workToDoAreaConeIcon', workToDoAreaConeIcon);
        azureMap.current.imageSprite.add('workToDoMultiAreasConeIcon', workToDoMultiAreasConeIcon);
        azureMap.current.imageSprite.add('workDoneAndToDoMultiAreasConeIcon', workDoneAndToDoMultiAreasConeIcon);

        return symbolLayer;
    }

    const createSelectedConeIconSymbolLayer = (datasource: source.DataSource, id: string): layer.SymbolLayer => {
        let symbolLayer: layer.SymbolLayer = new layer.SymbolLayer(datasource, id, {
            iconOptions: {
                image: ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workDoneArea], 'selectedWorkDoneAreaConeIcon',
                    ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workDoneMultiArea], 'selectedWorkDoneMultiAreasConeIcon',
                        ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workToDoArea], 'selectedWorkToDoAreaConeIcon',
                            ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workToDoMultiArea], 'selectedWorkToDoMultiAreasConeIcon',
                                ['case', ['==', ['get', 'EntityType'], areaConeEntityTypes.workToDoAndWorkDoneMultiArea], 'selectedWorkDoneAndToDoMultiAreasConeIcon', '']
                            ]
                        ]
                    ]
                ],
                anchor: 'center',
                ignorePlacement: true,
                allowOverlap: true,
                rotationAlignment: 'map'
            },
            filter: ['all', ['has', 'isSelectedConeIcon'], ['==', ['get', 'visible'], true]],
            zOrder: 'source'
        });

        azureMap.current.imageSprite.add('selectedWorkDoneAreaConeIcon', selectedWorkDoneAreaConeIcon);
        azureMap.current.imageSprite.add('selectedWorkDoneMultiAreasConeIcon', selectedWorkDoneMultiAreasConeIcon);
        azureMap.current.imageSprite.add('selectedWorkToDoAreaConeIcon', selectedWorkToDoAreaConeIcon);
        azureMap.current.imageSprite.add('selectedWorkToDoMultiAreasConeIcon', selectedWorkToDoMultiAreasConeIcon);
        azureMap.current.imageSprite.add('selectedWorkDoneAndToDoMultiAreasConeIcon', selectedWorkDoneAndToDoMultiAreasConeIcon);

        return symbolLayer;
    }

    const createHelmetDatasource = (): void => {
        let datasource = new source.DataSource(helmetDatasourceId);
        azureMap.current.sources.add(datasource);

        let helmetSymbolLayer = createHelmetIconSymbolLayer(datasource, helmetIconSymbolLayerId);
        azureMap.current.layers.add([helmetSymbolLayer]);
    }

    const createHelmetIconSymbolLayer = (datasource: source.DataSource, id: string): layer.SymbolLayer => {
        let symbolLayer: layer.SymbolLayer = new layer.SymbolLayer(datasource, id, {
            iconOptions: {
                image: 'helmetIcon',
                anchor: 'center',
                ignorePlacement: true,
                allowOverlap: true,
                rotationAlignment: 'map'
            }
        });

        azureMap.current.imageSprite.add('helmetIcon', securityHelmetIcon);

        return symbolLayer;
    }

    const createAddressDatasource = (): void => {
        let datasource = new source.DataSource(addressDatasourceId);
        azureMap.current.sources.add(datasource);

        let addressSymbolLayer = createAddressIconSymbolLayer(datasource, addressIconSymbolLayerId);
        azureMap.current.layers.add([addressSymbolLayer]);
    }

    const createAddressIconSymbolLayer = (datasource: source.DataSource, id: string): layer.SymbolLayer => {
        let symbolLayer: layer.SymbolLayer = new layer.SymbolLayer(datasource, id, {
            iconOptions: {
                image: 'addressIcon',
                anchor: 'bottom',
                ignorePlacement: true,
                allowOverlap: true,
                rotationAlignment: 'map'
            }
        });

        azureMap.current.imageSprite.add('addressIcon', pinIcon);

        return symbolLayer;
    }

    const createExtendedAreas = (programmings: Programming[], mergedProject: MergedProjectVersion): Map<number, ProgrammingAreaExtended> => {
        if (!programmings)
            return null;

        let areas: Map<number, ProgrammingAreaExtended> = new Map<number, ProgrammingAreaExtended>();
        programmings.forEach((programming: Programming) => {
            programming.areas.forEach((area: ProgrammingArea) => {
                let extendedArea = {
                    ...area,
                    programmingLabel: programming.label,
                    year: programming.year,
                    isEnlightened: false,
                    coordinates: null,
                    sortedCoordinates: null,
                    isFiltered: true
                } as ProgrammingAreaExtended;

                let areaCoordinates: data.Position[] = [];
                area.sections.forEach((section) => {
                    let coordinates = section.pathGeometry.coordinates;
                    areaCoordinates = areaCoordinates.concat(coordinates);
                });
                extendedArea.coordinates = areaCoordinates;
                extendedArea.sortedCoordinates = areaCoordinates.sort();

                areas.set(extendedArea.programmingAreaId, extendedArea);
            });
        });
        shapeAreasDico = areas;
        return areas;
    }

    const createMapAreasShapes = (areas: Map<number, ProgrammingAreaExtended>, mainDatasource: source.DataSource, coneDatasource: source.DataSource): void => {
        //NOTE HGA on les tri par id car on n'a pas la date de création, mais on souhaite que les plus récentes se dessinent par dessus
        const mapEntries = [...areas].map(([key, value]) => (value));

        orderBy(mapEntries, [(a) => { return a.programmingAreaId }], ['asc'])
            .forEach((entry: ProgrammingAreaExtended) => {
                let area: AreaShapeProps = {
                    programmingAreaId: entry.programmingAreaId,
                    year: entry.year,
                    status: entry.status,
                    hexColor: entry.hexColor,
                    label: entry.label,
                    selectedWork: entry.selectedWork,
                    lengthInLinearMeters: entry.lengthInLinearMeters,
                    areaInSquareMeters: entry.areaInSquareMeters,
                    budgetAmount: entry.budgetAmount,
                    isFiltered: entry.isFiltered,
                    isEnlightened: false,
                    programmingLabel: entry.programmingLabel,
                    programmingOptionLabel: `${entry.year} - ${entry.programmingLabel}`,
                    startDate: entry.startDate,
                    endtDate: entry.endtDate,
                    lastModificationUser: entry.lastModificationUser
                };

                entry.sections.forEach((section: AreaSection) => {
                    let coordinates = section.pathGeometry.coordinates;
                    let roadSectionId = section.roadSectionId;

                    let unselectedAreaShapeId = getAreaShapeId(roadSectionId);
                    let unselectedAreaShape = createShape(coordinates, unselectedAreaShapeId, area.hexColor, areaWidth, ShapeEntityType.area, roadSectionId, null, null, null, null, null, area);
                    mainDatasource.add(unselectedAreaShape);
                });
            });

        let areaGroupings = createAreasGroupings(areas);

        createConePins(areaGroupings, coneDatasource);
    }

    const createAreasGroupings = (areas: Map<number, ProgrammingAreaExtended>): Map<number, { areas: ProgrammingAreaExtended[], order: number }> => {
        let groupings = new Map<number, { areas: ProgrammingAreaExtended[], order: number }>();

        areas.forEach((area: ProgrammingAreaExtended) => {
            let isMatchOnExistingGroup: boolean = false;
            let groupNumber = 0;

            for (let x of groupings) {
                let groupKey = x[0];
                let groupValue = x[1];
                let firstExistingArea = groupValue.areas[0];
                isMatchOnExistingGroup = isEqual(firstExistingArea.sortedCoordinates, area.sortedCoordinates);
                if (isMatchOnExistingGroup) {
                    groupValue.order = Math.max(groupValue.order, area.programmingAreaId);
                    groupValue.areas.push(area);
                    groupings.set(groupKey, groupValue);
                    isMatchOnExistingGroup = true;
                    break;
                }
                groupNumber++;
            }

            if (!isMatchOnExistingGroup) {
                groupings.set(groupNumber, { areas: [area], order: area.programmingAreaId });
            }
        });

        return groupings;
    }

    const createConePins = (areaGroupings: Map<number, { areas: ProgrammingAreaExtended[], order: number }>, coneDatasource: source.DataSource): void => {
        let conePinIdsSet = new Set<string>();

        const mapEntries = [...areaGroupings].map(([key, value]) => (value));
        orderBy(mapEntries, [(a) => { return a.order }], ['asc'])
            .forEach((entry: { areas: ProgrammingAreaExtended[], order: number }) => {
                let firstArea = entry.areas[0];
                let coordinates = firstArea.coordinates;
                if (coordinates.length > 0) {
                    let areas: AreaShapeProps[] = [];
                    entry.areas.forEach((area: ProgrammingAreaExtended) => {
                        areas.push({
                            programmingAreaId: area.programmingAreaId,
                            year: area.year,
                            status: area.status,
                            hexColor: area.hexColor,
                            label: area.label,
                            selectedWork: area.selectedWork,
                            lengthInLinearMeters: area.lengthInLinearMeters,
                            areaInSquareMeters: area.areaInSquareMeters,
                            budgetAmount: area.budgetAmount,
                            isFiltered: area.isFiltered,
                            isEnlightened: false,
                            programmingLabel: area.programmingLabel,
                            programmingOptionLabel: `${area.year} - ${area.programmingLabel}`,
                            startDate: area.startDate,
                            endtDate: area.endtDate,
                            lastModificationUser: area.lastModificationUser
                        });
                    });

                    let props = { isConeIcon: true, EntityType: null, areas: areas, index: entry.order, visible: true };
                    let selectedProps = { isSelectedConeIcon: true, EntityType: null, areas: areas, index: entry.order, visible: false };

                    if (entry.areas.length === 1) {
                        if (firstArea.status === ProgrammingAreaStatus.workDone) {
                            props.EntityType = areaConeEntityTypes.workDoneArea;
                            selectedProps.EntityType = areaConeEntityTypes.workDoneArea;
                        }
                        else if (firstArea.status === ProgrammingAreaStatus.workToDo) {
                            props.EntityType = areaConeEntityTypes.workToDoArea;
                            selectedProps.EntityType = areaConeEntityTypes.workToDoArea;
                        }
                    }
                    else if (entry.areas.length > 1) {
                        let isAllWorkToDo = true;
                        let isAllWorkDone = true;
                        entry.areas.forEach(area => {
                            if (area.status === ProgrammingAreaStatus.workDone) {
                                isAllWorkToDo = false;
                            }
                            if (area.status === ProgrammingAreaStatus.workToDo) {
                                isAllWorkDone = false;
                            }
                        });

                        if (isAllWorkToDo && !isAllWorkDone) {
                            props.EntityType = areaConeEntityTypes.workToDoMultiArea;
                            selectedProps.EntityType = areaConeEntityTypes.workToDoMultiArea;
                        }
                        else if (!isAllWorkToDo && isAllWorkDone) {
                            props.EntityType = areaConeEntityTypes.workDoneMultiArea;
                            selectedProps.EntityType = areaConeEntityTypes.workDoneMultiArea;
                        }
                        else {
                            props.EntityType = areaConeEntityTypes.workToDoAndWorkDoneMultiArea;
                            selectedProps.EntityType = areaConeEntityTypes.workToDoAndWorkDoneMultiArea;
                        }
                    }

                    let boundingBox = data.BoundingBox.fromPositions(coordinates);
                    let centralPoint = data.BoundingBox.getCenter(boundingBox);
                    let distances = new Map<number, data.Position>();
                    coordinates.forEach((position) => {
                        let distance = math.getDistanceTo(centralPoint, position);
                        distances.set(distance, position);
                    });

                    let minDistance = Math.min(...Array.from(distances).map(x => x[0]));
                    let midPoint = distances.get(minDistance);

                    let coneShapeId = getConeShapeId(entry.order);
                    let conePin = new data.Feature(new data.Point(midPoint), props, coneShapeId);
                    coneDatasource.add(conePin);
                    conePinIdsSet.add(coneShapeId);

                    let selectedConeShapeId = getSelectedConeShapeId(entry.order);
                    let selectedConePin = new data.Feature(new data.Point(midPoint), selectedProps, selectedConeShapeId);
                    coneDatasource.add(selectedConePin);
                    conePinIdsSet.add(selectedConeShapeId);
                }
            });

        conePinIds = conePinIdsSet;
    }

    const removeConePins = (datasource: source.DataSource): void => {
        conePinIds.forEach((coneId: string) => {
            let shape = datasource.getShapeById(coneId);
            if (shape) {
                datasource.remove(shape);
            }
        });
    }

    const getConeShapeId = (index: number): string => {
        return `cone-${index}`;
    }

    const getSelectedConeShapeId = (index: number): string => {
        return `cone-${index}-selected`;
    }

    const setMapZoom = (map: AzureMap, mergedProject: MergedProjectVersion): void => {
        let options: CameraBoundsOptions & AnimationOptions = {
            bounds: data.BoundingBox.fromBoundingBox(new data.BoundingBox(mergedProject.southWesternBoundingLocationGeometry.coordinates, mergedProject.northEasternBoundingLocationGeometry.coordinates)),
            padding: 20
        };

        options.type = cameraAnimationType;
        options.duration = cameraAnimationDuration;

        map.setCamera(options);
    }

    const updateSectionsVisibility = (sections: Map<number, RoadSectionViewData>, activeQualities: Set<number>): Set<number> => {
        let filterdSections = new Set<number>();

        sections.forEach((section) => {
            if (activeQualities && activeQualities.size > 0 && activeQualities.has(section.score)) {
                if (!filterdSections.has(section.roadSectionId)) {
                    filterdSections.add(section.roadSectionId);
                }
            }
            else {
                if (filterdSections.has(section.roadSectionId)) {
                    filterdSections.delete(section.roadSectionId);
                }
            }
        });

        return filterdSections;
    }

    const handleLayerMouseover = (): void => {
        setMapCursor(azureMap.current, MapCursorMode.Pointer);
    }

    const handleLayerMouseout = (): void => {
        setMapCursor(azureMap.current, MapCursorMode.Auto);
    }

    const createAddressIconPin = (coordinates: data.Position, datasource: source.DataSource): void => {
        let pin = new data.Feature(new data.Point(coordinates), { EntityType: addressEntityType }, addressId);
        datasource.add(pin);
    }

    const removeAddressIconPin = (datasource: source.DataSource): void => {
        let shape = datasource.getShapeById(addressId);
        if (shape) {
            datasource.remove(shape);
        }
    }

    const showShape = (shape: Shape, strokeColor: string, shapeProperties: any): void => {
        shapeProperties.strokeColor = strokeColor;
        shapeProperties.strokeWidth = areaWidth;
        shape.setProperties(shapeProperties);
    }

    const showAreaShape = (shape: Shape): void => {
        let props = shape.getProperties();
        props.strokeColor = props.areaProps.hexColor;
        props.strokeWidth = areaWidth;
        shape.setProperties(props);
    }

    const isShapeVisible = (shapeProperties: any): boolean => {
        return shapeProperties.strokeColor !== transparentColor && shapeProperties.strokeWidth !== 0;
    }

    return (
        <Box className={`follow-up-works-map ${props.isRoadWorksInformationDrawerOpened ? 'opened-drawer' : ''}`}>
            <div id="AzureMap"></div>
            <Box className="map-actions" display="flex" flexDirection="column" alignItems="center">
                {azureMap.current &&
                    <MapActionBar
                        azureMap={azureMap.current}
                        actions={[]}
                        onSelectedSectionChange={null}
                        selectedSectionsId={[]}
                        sections={null}
                        mainLayer={null}
                        currentMeasurementSystemType={null}
                    />
                }
            </Box>
        </Box>
    )
}